import Client from "@/core/classes/client/Client";
import BaseService from "@/core/classes/base/BaseService";

import {
  getProjectsAPI,
  getProjectsTagsAPI,
  getProjectAPI,
} from "./ProjectsFair.api";

export default class HomeService extends BaseService {
  static getPaginatedProjects(filteredTagId = null, pageNumber = 1) {
    return Client.get(getProjectsAPI, {
      filter_by: filteredTagId,
      page: pageNumber,
    }).then((res) => Promise.resolve(res));
  }
  static getProjectsTags() {
    return Client.get(getProjectsTagsAPI).then((res) => Promise.resolve(res));
  }
  static getProject(id) {
    return Client.get(getProjectAPI(id)).then((res) => Promise.resolve(res));
  }
}
