import BaseService from "@/core/classes/base/BaseService";
import { getProfileAPI, changePasswordAPI } from "./Profile.api";
import Client from "@/core/classes/client/Client";

export default class ProfileService extends BaseService {
  static getProfile() {
    return Client.get(getProfileAPI).then((res) => Promise.resolve(res));
  }
  static changePassword(data) {
    return Client.put(changePasswordAPI, data).then((res) =>
      Promise.resolve(res)
    );
  }
}
