<template>
  <v-col cols="12" md="4" align="center" justify="center">
    <AddButton :title="this.$t('certifications')" @click="openDialog()" />
    <BaseDraggable v-model="resume.certificates">
      <DeletableCard
        v-for="(certificate, i) in resume.certificates"
        :key="i"
        :index="i"
        name="certificates"
      >
        <p class="primary--text">
          {{ certificate.name }} | {{ certificate.issuer }}
        </p>
        <p class="primary--text">
          {{ certificate.issueDate }}
        </p>
        <p v-if="certificate.description" class="primary--text">
          {{ certificate.description }}
        </p>
        <p v-if="certificate.link" class="primary--text">
          {{ certificate.link }}
        </p>
      </DeletableCard>
    </BaseDraggable>
    <AddCertificateDialog :showCondition="showDialog" @closed="closeDialog()" />
  </v-col>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "../../store/CreateResumeStore";

import AddButton from "../buttons/AddButton.vue";
import DeletableCard from "../DeletableCard.vue";
import AddCertificateDialog from "../dialogs/AddCertificateDialog.vue";

export default {
  components: { AddButton, DeletableCard, AddCertificateDialog },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    ...mapState(useCreateResumeStore, ["resume"]),
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style></style>
