export default class Link {
  title;
  icon;
  url;
  constructor(data) {
    this.title = data?.title;
    this.icon = data?.icon;
    this.url = data?.url;
  }
}
