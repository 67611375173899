import BaseService from "@/core/classes/base/BaseService";
import {
  getJobsAPI,
  getAllJobSuggestionsAPI,
  getJobAPI,
  getJobSuggestionsAPI,
  sendJobApplicationAPI,
  sendJobFeedbackAPI,
  getAppliedJobsAPI,
  getJobFieldsAPI,
  sendTrainerResumeAPI
} from "./JobOpportunities.api";
// import Mapper from "@/core/classes/mapper/Mapper";
import Client from "@/core/classes/client/Client";

export default class JobOpportunitiesService extends BaseService {
  static getPaginatedJobOpportunities(params) {
    return Client.get(getJobsAPI, params).then(res => Promise.resolve(res));
  }
  static getPaginatedJobSuggestions(params) {
    return Client.get(getAllJobSuggestionsAPI, params).then(res => Promise.resolve(res));
  }
  static getJobFields(params) {
    return Client.get(getJobFieldsAPI, params).then(res => Promise.resolve(res));
  }
  static getJobOpportunity(id) {
    return Client.get(getJobAPI(id)).then(res => Promise.resolve(res));
  }
  static getJobSuggestions(id) {
    return Client.get(getJobSuggestionsAPI(id)).then(res => Promise.resolve(res));
  }
  static sendJobApplication(id, data) {
    return Client.post(sendJobApplicationAPI(id), data, {
      headers: {
        "content-type": "multipart/form-data"
      }
    }).then(res => Promise.resolve(res));
  }
  static sendJobFeedback(id,data){
    return Client.post(sendJobFeedbackAPI(id),data).then(res => Promise.resolve(res));
  }
  static getAppliedJobs(params) {
    return Client.get(getAppliedJobsAPI, params).then(res => Promise.resolve(res));
  }
  static sendTrainerResume(data) {
    return Client.post(sendTrainerResumeAPI, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }).then((res) => Promise.resolve(res));
  }
}
