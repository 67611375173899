<template>
  <v-row align="center" :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'start'">
    <p class="secondary--text mx-sm-6 pb-sm-3 text-center" style="min-width: 120px">
      {{ this.title }}
    </p>
    <slot></slot>
  </v-row>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style></style>
