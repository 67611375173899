<template>
  <v-app-bar
    class="border"
    :color="navColor"
    height="100px"
    :clipped-right="true"
    flat
    fixed
    elevate-on-scroll
    elevation="5"
  >
    <!--drawer button for small screens-->
    <v-app-bar-nav-icon
      class="d-lg-none absolute"
      @click="toggleDrawer()"
    ></v-app-bar-nav-icon>
    <!--Logo-->
    <v-spacer></v-spacer>
    <button class="invisible-btn ml-3" @click="$router.push('/')">
      <v-img
        src="@/assets/logos/vector-logo.svg"
        class="mr-5"
        width="75"
      ></v-img>
    </button>
    <!--TABS-->
    <v-tabs centered optional class="d-none d-lg-inline">
      <v-tabs-slider color="transparent"></v-tabs-slider>
      <v-tab
        class="f-w-6"
        v-for="tab in tabList"
        :key="tab.title"
        :to="tab.link"
      >
        {{ tab.title }}
      </v-tab>
    </v-tabs>
    <v-spacer></v-spacer>
    <!--Authentication buttons/user profile-->
    <div class="d-none d-lg-flex">
      <template v-if="!isAuthenticated">
        <SignUpButton />
        <SignInButton />
      </template>
      <template v-else>
        <UserTopNavMenu />
      </template>
    </div>
  </v-app-bar>
</template>

<script>
import { useGlobalStore } from "@/store/GlobalStore";
import { useAuthStore } from "@/modules/auth/store/AuthStore";
import { mapActions, mapState } from "pinia";
import TabsList from "./TabsList";
import SignUpButton from "./components/SignUpButton";
import SignInButton from "./components/SignInButton";
import UserTopNavMenu from "./components/UserTopNavMenu";

export default {
  components: {
    SignUpButton,
    SignInButton,
    UserTopNavMenu,
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  data() {
    return {
      navColor: "transparent",
      tabList: TabsList,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["isAuthenticated"]),
  },
  methods: {
    ...mapActions(useGlobalStore, ["changeDrawerVisibility"]),
    toggleDrawer() {
      this.changeDrawerVisibility();
    },
    changeColor() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        this.navColor = "lightNeutral";
      } else {
        this.navColor = "transparent";
      }
    },
  },
};
</script>

<style scoped>
.f-w-6 {
  font-weight: 600;
}
.absolute{
  position: absolute;
}
</style>
