import { defineStore } from "pinia";

import AuthService from "../services/AuthService";
import BaseStore from "@/core/classes/base/BaseStore";

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    isLogoutDialogVisible: false,
    username: localStorage.getItem("username") ?? null,
    isAuthenticated: localStorage.getItem("token") != null,
    email: localStorage.getItem("email") ?? null,
    phoneNumber: localStorage.getItem("phoneNumber") ?? null,
    loginDialogState: {
      show: false,
      persistent: false,
    },
    registerDialogState: {
      show: false,
      persistent: false,
    },
    emailVerificationDialogState: {
      show: false,
      persistent: false,
    },
    phoneVerificationDialogState: {
      show: false,
      persistent: false,
    },
    emailDialogState: {
      show: false,
      persistent: false,
      profile: false, // This attribute assists us in formulating appropriate dialog titles, depending on whether the dialog is opened from the profile page or not.
    },
  }),
  getters: {},
  actions: {
    changeLogoutDialogVisibility() {
      this.isLogoutDialogVisible = !this.isLogoutDialogVisible;
    },
    changeEmailDialogState(show, persistent, profile) {
      this.emailDialogState.show = show ?? this.emailDialogState.show;
      this.emailDialogState.persistent =
        persistent ?? this.emailDialogState.persistent;
      this.emailDialogState.profile = profile ?? false;
    },
    changeLoginDialogState(show, persistent) {
      this.loginDialogState.show = show ?? this.loginDialogState.show;
      this.loginDialogState.persistent =
        persistent ?? this.loginDialogState.persistent;
    },
    changeRegisterDialogState(show, persistent) {
      this.registerDialogState.show = show ?? this.registerDialogState.show;
      this.registerDialogState.persistent =
        persistent ?? this.registerDialogState.persistent;
    },
    changePhoneVerificationDialogState(show, persistent) {
      this.phoneVerificationDialogState.show =
        show ?? this.phoneVerificationDialogState.show;
      this.phoneVerificationDialogState.persistent =
        persistent ?? this.phoneVerificationDialogState.persistent;
    },
    changeEmailVerificationDialogState(show, persistent) {
      this.emailVerificationDialogState.show =
        show ?? this.emailVerificationDialogState.show;
      this.emailVerificationDialogState.persistent =
        persistent ?? this.emailVerificationDialogState.persistent;
    },
    setLocalStorageData(data) {
      localStorage.setItem("token", data.data.token);
      localStorage.setItem("username", data.data.name);
      localStorage.setItem("email", data.data.email);
      localStorage.setItem("phoneNumber", data.data.phone_number);
    },
    setLocalStateData(data) {
      this.username = data.data.name;
      this.email = data.data.email;
      this.phoneNumber = data.data.phoneNumber;
      this.isAuthenticated = true;
    },
    login(data) {
      return BaseStore.promiseHandler(
        () => AuthService.login(data),
        (data) => {
          this.setLocalStateData(data);
          this.setLocalStorageData(data);
          this.changeLoginDialogState(false);
        }
      );
    },
    register(registerData) {
      return BaseStore.promiseHandler(
        () => AuthService.register(registerData),
        (data) => {
          this.setLocalStateData(data);
          this.setLocalStorageData(data);
          this.changeRegisterDialogState(false);
          this.changePhoneVerificationDialogState(
            true,
            this.registerDialogState.persistent
          );
        }
      );
    },
    verifyEmail(data) {
      return BaseStore.promiseHandler(
        () => AuthService.verifyEmail(data),
        () => {
          this.changeEmailVerificationDialogState(false);
        }
      );
    },
    verifyPhone(data) {
      return BaseStore.promiseHandler(
        () => AuthService.verifyPhone(data),
        () => {
          this.changePhoneVerificationDialogState(false);
        }
      );
    },
    addEmail(data) {
      return BaseStore.promiseHandler(
        () => AuthService.addEmail(data),
        () => {
          this.email = data.email;
          localStorage.setItem("email", data.email);
          this.changeEmailDialogState(false);
          this.changeEmailVerificationDialogState(true, true);
        }
      );
    },
    resendVerificationCode() {
      return BaseStore.promiseHandler(() =>
        AuthService.resendVerificationCode()
      );
    },
    resendPhoneVerificationCode() {
      return BaseStore.promiseHandler(() =>
        AuthService.resendPhoneVerificationCode()
      );
    },
    logout() {
      return BaseStore.promiseHandler(
        () => AuthService.logout(),
        () => {
          this.localLogout();
          this.changeLogoutDialogVisibility();
        }
      );
    },
    localLogout() {
      localStorage.clear();
      this.isAuthenticated = false;
      this.username = null;
      this.email = null;
      this.phoneNumber = null;
    },
  },
});
