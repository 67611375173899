import TrainingProgram from "@/core/classes/models/TrainingProgram";
import TrainingProgramSuggestion from "@/core/classes/models/TrainingProgramSuggestion";
import { defineStore } from "pinia";
import TrainingProgramApplicationService from "../services/TrainingProgramApplicationService";
// import BaseStore from "@/core/classes/base/BaseStore";

export const useTrainingProgramsStore = defineStore("trainingProgramsStore", {
  state: () => ({
    trainingPrograms: [],
    trainingProgramSuggestion: new TrainingProgramSuggestion(),
    suggestingIsEnabled: true,
  }),
  getters: {},
  actions: {
    fetchTrainingPrograms() {
      return new Promise((resolve, reject) => {
        TrainingProgramApplicationService.getAllTrainingPrograms()
          .then((res) => {
            let { data, status } = res;
            return TrainingProgramApplicationService.resolveStatusResponse(
              status,
              res
            ).then(() => {
              data.data.map((tp) => {
                this.trainingPrograms.push(new TrainingProgram(tp));
              });
              return resolve(res);
            });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    postTrainingProgramSuggestion() {
      return new Promise((resolve, reject) => {
        TrainingProgramApplicationService.sendTrainingProgramSuggestion(
          this.trainingProgramSuggestion
        )
          .then((res) => {
            let { data, status } = res;
            data;
            return TrainingProgramApplicationService.resolveStatusResponse(
              status,
              res
            ).then(() => {
              return resolve(res);
            });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    fetchSuggestingStatus() {
      return new Promise((resolve, reject) => {
        TrainingProgramApplicationService.getTPSuggestingStatus()
          .then((res) => {
            let { data, status } = res;
            return TrainingProgramApplicationService.resolveStatusResponse(
              status,
              res
            ).then(() => {
              this.suggestingIsEnabled = data.data.is_enabled;
              return resolve(res);
            });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});
