<template>
  <BaseDecoratorWrapper>
    <v-container>
      <v-row v-if="!fetchingData" justify="center">
        <v-col cols="12"  align="center">
          <v-col cols="12" sm="9" md="8">
            <JobDetails :job="job" />
          </v-col>
          <v-col v-if="relatedJobs.length != 0">
            <h2 class="my-5 secondary--text">فرص العمل الشبيهة</h2>
            <JobCardsSlider :jobSuggestions="relatedJobs" />
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </BaseDecoratorWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import JobDetails from "../components/JobDetails.vue";
import JobCardsSlider from "../components/JobCardsSlider.vue";
import { useJobOpportunityStore } from "../store/JobOpportunityStore";

export default {
  components: { JobDetails, JobCardsSlider },
  mounted() {
    var jobId = this.$route.params.id;
    this.fetchJob(jobId)
      .then(() => {
        this.fetchingData = false;
      })
      .catch((err) => {
        console.error(err);
      });
    this.fetchRelatedJobs(jobId)
      .then()
      .catch((err) => {
        console.error(err);
      });
  },
  data() {
    return {
      fetchingData: true,
    };
  },
  computed: {
    ...mapState(useJobOpportunityStore, ["job", "relatedJobs"]),
  },
  methods: {
    ...mapActions(useJobOpportunityStore, ["fetchJob", "fetchRelatedJobs"]),
  },
};
</script>
