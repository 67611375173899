import { defineStore } from "pinia";

import Project from "../models/Project";
import ProjectTag from "../models/ProjectTag";
import BaseStore from "@/core/classes/base/BaseStore";
import ProjectsFairService from "../services/ProjectsFairService";
import PaginatedProjectsCollection from "../models/PaginatedProjectsCollection";

export const useProjectsFairStore = defineStore("projectsFairStore", {
  state: () => ({
    project: new Project(),
    tags: [],
    selectedFilterTag: null,
    paginatedProjects: new PaginatedProjectsCollection(),
  }),
  actions: {
    fetchProjectsTags() {
      return BaseStore.promiseHandler(
        () => ProjectsFairService.getProjectsTags(),
        (data) => {
          this.tags = []; //reset tags collection
          data.data.map((tag) => {
            this.tags.push(new ProjectTag(tag));
          });
        }
      );
    },
    fetchPaginatedProjects() {
      return BaseStore.promiseHandler(
        () =>
          ProjectsFairService.getPaginatedProjects(
            this.selectedFilterTag?.id,
            this.paginatedProjects.currentPage
          ),
        (data) => {
          this.paginatedProjects = new PaginatedProjectsCollection(data);
        }
      );
    },
    fetchNextPage() {
      !this.paginatedProjects.isLastPage
        ? this.paginatedProjects.currentPage++
        : console.warn("no more pages!");
      this.fetchPaginatedProjects();
    },
    fetchProject(id) {
      return BaseStore.promiseHandler(
       () => ProjectsFairService.getProject(id),
        (data) => {
          this.project = new Project(data.data);
        }
      );
    },
    fetchPreviousPage() {
      this.paginatedProjects.currentPage != 1
        ? this.paginatedProjects.currentPage--
        : console.warn("no previous pages!");
      this.fetchPaginatedProjects();
    },
    /**
     * we need this function to reset current page to 1 whenever a filter state changes
     * @param TagObject || undefined : selectedFilterTag
     */
    setFilterStateAndRefetch(selectedFilterTag) {
      this.selectedFilterTag = selectedFilterTag;
      this.resetPaginatedProjects();
      this.fetchPaginatedProjects();
    },
    resetPaginatedProjects() {
      this.paginatedProjects = new PaginatedProjectsCollection();
    },
  },
});
