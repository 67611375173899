<!-- 
  [reverse] attribute will not make any effect outside the create resume module because it's always false
  and every time the user close create resume page the store will be reset to the default values (language='ar')
 -->
<!-- TODO: Fix text alignment in the first slot-->
<template>
  <v-select
    v-on="$listeners"
    v-bind="$attrs"
    :reverse="selectedLanguage == 'en'"
    outlined
    persistent-placeholder
  >
    <template v-slot:item="{ item }">
      <slot name="item" :item="item"></slot>
    </template>
    <template v-slot:selection="{ item }">
      <slot name="selection" :item="item"></slot>
    </template>
  </v-select>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "@/modules/create_resume/store/CreateResumeStore";

export default {
  name: "BaseSelect",
  computed: {
    ...mapState(useCreateResumeStore, ["selectedLanguage"]),
  },
};
</script>

<style scoped></style>
