var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"bottom":"","open-delay":100},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({class:[
          'mx-2 floating-btn-1',
          {
            'pos-sm': _vm.$vuetify.breakpoint.smAndDown,
            'pos-md': _vm.$vuetify.breakpoint.mdAndUp,
          } ],attrs:{"fab":"","dark":"","small":"","color":"accent"},on:{"click":function($event){return _vm.redirectToFAQs()}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("mdi-frequently-asked-questions")])],1)]}}])},[_c('span',[_vm._v(" الأسئلة الشائعة")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }