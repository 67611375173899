<template>
  <v-col cols="12" sm="6" align="center" justify="center">
    <v-card max-height="250" :to="path" max-width="350px" hover color="transparent">
      <v-img
        :src="require('@/assets/illustrations/home/' + imgName + '.svg')"
        contain
        aspect-ratio="2"
      ></v-img>
      <v-card-text class="text-center">
        <h2 class="secondary--text font-weight-bold">
          {{ this.title }}
        </h2>
        <br />
        <!-- <span>
          {{ this.description }}
        </span> -->
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    title: String,
    imgName: String,
    description: String,
    path: String,
  },
  methods: {
    pushSelectedServicePage() {
      this.$router.push({ path: this.path });
    },
  },
};
</script>

<style scoped></style>
