<template>
  <BaseDialog
    :ltr="selectedLanguage == 'en'"
    :showCondition="show"
    @closed="closeDialog()"
  >
    <template v-slot:title> {{ title }} </template>
    <template v-slot:body>
      <v-form ref="form">
        <BaseTextField
          v-model="skill.title"
          :rules="[requiredRule]"
          :label="skillName"
          :placeholder="skillPlaceholder"
          @keydown.enter="validateThenAddSkill()"
        >
        </BaseTextField>
        <input />
      </v-form>
    </template>

    <template v-slot:actions>
      <v-btn color="accent" rounded @click="validateThenAddSkill()">
        {{ add }}
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import { mapWritableState } from "pinia";
import { useCreateResumeStore } from "../../store/CreateResumeStore";
import ResumeValidationMixin from "../ResumeValidationMixin.vue";
import BaseDialog from "@/core/base/dialogs/BaseDialog.vue";
import SoftSkill from "../../models/SoftSkill";

export default {
  mixins:[ResumeValidationMixin],
  data() {
    return {
      show: this.showCondition,
      skill: new SoftSkill(),
    };
  },
  components: { BaseDialog },
  props: {
    showCondition: Boolean,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  computed: {
    ...mapWritableState(useCreateResumeStore, ["resume", "selectedLanguage"]),
    title() {
      return this.$t("add_soft_skill");
    },
    add() {
      return this.$t("add");
    },
    skillName() {
      return this.$t("skill_name");
    },
    skillPlaceholder() {
      return this.$t("ph_soft_skill");
    },
  },
  methods: {
    reset() {
      this.skill = new SoftSkill();
      this.$refs.form.reset();
    },
    closeDialog() {
      this.reset();
      this.show = false;
      this.$emit("closed");
    },
    validateThenAddSkill() {
      if (this.$refs.form.validate()) {
        this.resume.softSkills.push(this.skill);
        this.closeDialog();
      }
    },
  },
};
</script>
