import JobOpportunity from "@/core/classes/models/JobOpportunity";
import { defineStore } from "pinia";
import JobOpportunitiesService from "../services/JobOpportunitiesService";
import { useAppliedJobOpportunitiesStore } from "./AppliedJobOpportunitiesStore";

const appliedJobOpportunitiesStore=useAppliedJobOpportunitiesStore();

export const useJobOpportunityStore = defineStore("jobOpportunityStore", {
  state: () => ({
    job: new JobOpportunity(),
    relatedJobs:[],
  }),
  getters: {
    allAppliedJobs(){
      return appliedJobOpportunitiesStore.paginatedAppliedJobs.collection;
    }
  },
  actions: {
    fetchJob(id) {
      return new Promise((resolve, reject) => {
        JobOpportunitiesService.getJobOpportunity(id)
          .then(res => {
            let { data, status } = res;
            return JobOpportunitiesService.resolveStatusResponse(
              status,
              res
            ).then(() => {
              this.job = new JobOpportunity(data.data);
              return resolve(res);
            });
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    fetchRelatedJobs(id){
      return new Promise((resolve, reject) => {
        JobOpportunitiesService.getJobSuggestions(id)
          .then(res => {
            let { data, status } = res;
            return JobOpportunitiesService.resolveStatusResponse(
              status,
              res
            ).then(() => {
              this.relatedJobs=[];
              data.data.map((job)=>{
                this.relatedJobs.push(new JobOpportunity(job));
              })
              return resolve(res);
            });
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    sendJobApplication(id,applicationData){
        return new Promise((resolve, reject) => {
            JobOpportunitiesService.sendJobApplication(id,applicationData)
              .then(res => {
                let { data, status } = res;
                data;
                return JobOpportunitiesService.resolveStatusResponse(
                  status,
                  res
                ).then(() => {
                  this.job.userAppliedBefore=true;
                  return resolve(res);
                });
              })
              .catch(err => {
                reject(err);
              });
          });
    },
    sendJobFeedback(id,feedbackData){
        return new Promise((resolve, reject) => {
            JobOpportunitiesService.sendJobFeedback(id,feedbackData)
              .then(res => {
                let { data, status } = res;
                data;
                return JobOpportunitiesService.resolveStatusResponse(
                  status,
                  res
                ).then(() => {
                  //set the feedback status of the job in both applied jobs and job details
                  this.job.sentFeedback=feedbackData;
                  this.allAppliedJobs.map((job)=>{
                    if(job.id==id)
                      job.sentFeedback=feedbackData;
                  })
                  return resolve(res);
                });
              })
              .catch(err => {
                reject(err);
              });
          });
    },
  }
});
