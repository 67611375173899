<template>
  <v-container v-if="resume.softSkills.length > 0" class="pt-0 pb-0">
    <BaseDivider :title="this.$t('soft_skills')" />
    <v-chip
      v-for="(skill, i) in resume.softSkills"
      :key="i"
      style="margin-left: 8px; margin-bottom: 16px"
    >
      {{ skill.title }}
    </v-chip>
  </v-container>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "@/modules/create_resume/store/CreateResumeStore";
import BaseDivider from "./BaseDivider.vue";
export default {
    computed: {
        ...mapState(useCreateResumeStore, ["resume"]),
    },
    components: { BaseDivider }
};
</script>

<style></style>
