<!-- 
  [reverse] attribute will not make any effect outside the create resume module because it's always false
  and every time the user close create resume page the store will be reset to the default values (language='ar')
 -->
<template>
  <v-text-field
    v-on="$listeners"
    v-bind="$attrs"
    :reverse="selectedLanguage == 'en'"
    outlined
    persistent-placeholder
  ></v-text-field>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "@/modules/create_resume/store/CreateResumeStore";

export default {
  name: "BaseTextField",
  computed: {
    ...mapState(useCreateResumeStore, ["selectedLanguage"]),
  },
};
</script>
