import UserLayout from "@/core/layouts/UserLayout.vue";
import ProjectsFairPage from "../pages/ProjectsFairPage.vue";
import ProjectPage from "../pages/ProjectPage.vue";

export default {
  path: "/projects-fair",
  component: UserLayout,
  children: [
    {
      path: "/projects-fair",
      name: "projects-fair",
      component: ProjectsFairPage,
      meta: { title: "المشاريع المميزة" },
    },
    {
      path: "/projects-fair/:id",
      name: "project",
      component: ProjectPage,
      meta: {
        title: "تفاصيل المشروع", // TODO: Get title from route params
      },
    },
  ],
};
