<template>
  <v-progress-linear
    :color="selectedColor"
    :value=" proficiencyPercentage()"
    :reverse="selectedLanguage == 'en'"
    height="15"
  >
    <strong class="white--text">{{ proficiencyPercentage() }}%</strong>
  </v-progress-linear>
</template>

<script>
import { useCreateResumeStore } from '@/modules/create_resume/store/CreateResumeStore';
import { mapState } from 'pinia';

export default {
  props: {
    value: Number,
  },
  computed: {
        ...mapState(useCreateResumeStore, ["selectedColor","selectedLanguage"]),
    },
  methods: {
    proficiencyPercentage() {
      return this.value * 20;
    },
  },
};
</script>

<style></style>
