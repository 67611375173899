<template>
  <v-container>
    <v-row align="center">
      <v-col cols="5" class="px-0">
        <h2>{{ title }}</h2>
      </v-col>
      <v-col  cols="7">
        <div>
          <v-divider :color="selectedColor"></v-divider>
          <v-divider :color="selectedColor"></v-divider>
          <v-divider :color="selectedColor"></v-divider>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useCreateResumeStore } from '@/modules/create_resume/store/CreateResumeStore';
import { mapState } from 'pinia';

export default {
  props: {
    title: String,
  },
  computed: {
      ...mapState(useCreateResumeStore, [
        "selectedColor",
      ]),
    }
};
</script>

<style></style>
