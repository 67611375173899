<template>
    <v-container><!-- v-if="!testStarted"-->
        <v-row justify="center">
            <h2 class="mb-5 secondary--text">نتيجة الاختبار النهائية غير دقيقة</h2>
            <v-row justify="center" class="mt-5">
                <v-col align="center" align-self="center" cols="10" sm="8" md="5">
                    <div class="mb-5">
                        إن النتيجة النهائية للاختبار غير دقيقة الرجاء إعادة الاختبار والالتزام بالمنطقية أثناء اختيار
                        الإجابات لعدم حدوث تعارضات بين الأسئلة والتي بالتالي ستؤدي إلى الوصول إلى نتيجة غير منطقية.
                    </div>
                    <v-btn id="redo-test-btn" color="accent" rounded dark @click="redoTest()">
                        إعادة الاختبار
                        <v-icon class="mr-3"> mdi-replay</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="0" md=3 class="d-none d-md-flex">
                    <v-img src="@/assets/illustrations/training_programs/repeat.svg"></v-img>
                </v-col>
            </v-row>
        </v-row>
    </v-container>
</template>
<script>
import { useCareerAptitudeTestStore } from '../store/CareerAptitudeTestStore';
import { mapActions } from 'pinia';

export default {
    data() {
        return {
        }
    },
   methods:{
    ...mapActions(useCareerAptitudeTestStore,['resetTestResultAndRedoTest']),
    redoTest(){
        this.resetTestResultAndRedoTest();
    }
   }
}
</script>