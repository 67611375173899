export default class WorkExperience {
  title;
  company;
  description;
  location;
  startDate;
  endDate;
  present = false;
  constructor(data) {
    this.title = data?.title;
    this.company = data?.company;
    this.location = data?.location;
    this.description = data?.description;
    this.startDate = data?.startDate;
    this.endDate = data?.endDate;
    this.present = data?.present;
  }
}
