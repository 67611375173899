export default class VolunteerExperience {
  role;
  organization;
  description;
  startDate;
  endDate;
  present = false;
  constructor(data) {
    this.role = data?.role;
    this.organization = data?.organization;
    this.description = data?.description;
    this.present = data?.present;
    this.startDate = data?.startDate;
    this.endDate = data?.endDate;
  }
}
