<template>
  <v-app>
    <v-main class="lightNeutral">
      <router-view :key="$route.fullPath" />
    </v-main>
  </v-app>
</template>

<script>
export default {};
</script>

<style>
* {
  letter-spacing: normal !important;
}
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  background: #f0ebe6;
}
::-webkit-scrollbar-thumb {
  background-color: #decebe;
}
</style>
