export default {
  R: {
    title:"الواقعي",
    description:
      "صريح، ثابت، و عملي يحب التعامل مع الآلات و الأدوات، يحب الأعمال التي تتطلب تقنية و مهارة معينة،غالبا ما يحب العمل اليدوي أو العمل بواسطة الآلات و الأدوات و الأشياء، أو التعامل مع الحيوانات.",
    professions: `يتميز صاحب هذا النمط في الأعمال اليدوية أو البدنية التي تتطلب تناسقا أو مهارة أو مجهودا و قوة .
        كطب الأسنان والجراحة وبعض الأعمال الهندسية والصيانة والرياضة والحرف اليدوية والزراعة وتربية
        الحيوانات...الخ`,
    illustration: "r.svg"
  },
  I: {
    title:"المستكشف",
    description: `من سمات هذه الشخصية أيضا: التحليل الفضول، و الاستقلالية، العقلانية، و التحليل. يحب إجراء التجارب
        و الإشراف على البحوث و العمل عن طريق النظريات و المعلومات، التفكير والفهم.`,
    professions: `يتميز في المجالات التي تتطلب ذلك كالتشخيص والبحث العلمي والرياضيات وعلوم الفيزياء والكيمياء
        والفلك، والبرمجة ......الخ`,
    illustration: "i.svg"
  },
  A: {
    title:"الفنان",
    description: `يفضل الأعمال الإبداعية، الأصيلة، و غير المنهجية، التي تحفز القدرة على الإبداع و الابتكار.
        من سمات هذه الشخصية أيضا: التخيل، عدم التنظيم، المثالية، الأحاسيس و المشاعر، غير عملي، غير
        منظم، لديه حدس، ابتكاري، يعتمد على الأحاسيس، الخيال، و الإلهام. يتسم بالعفوية و الانفتاح`,
    professions: `يتميز في المجالات التي تتطلب ابتكارا كالتصميم الإعلاني أو الهندسي وهندسة الديكور واكتشاف وتطوير
        المنتجات والخدمات والرسم والمسرح والموسيقى .........الخ`,
    illustration: "a.svg"
  },
  S: {
    title:"الاجتماعي",
    description: `من سمات صاحب هذه الشخصية أنه: متعاون، لطيف، اجتماعي، متفهم، صبور، يعتني بالأشخاص. يحب
        الأعمال التي تكون ضمن فريق، التفاعل الاجتماعي، بناء العلاقات، و الارتقاء بالمجتمع
        يفضل الأعمال التي لها طابع خيري اجتماعي يساهم في مساعدة الآخرين، تعليمهم، شفائهم أو تطويرهم
        نحو الأفضل.`,
    professions: `يتميز في الأعمال التي ترتبط بالآخرين وتعتمد على حسن التواصل معهم كالتدريس والتدريب ، الطب
        والتمريض، الموارد البشرية، العلاقات العامة ......الخ`,
    illustration: "s.svg"
  },
  E: {
    title:"المبادر (القائد)",
    description: `من سمات صاحب هذه الشخصية أنه: طموح، مهيمن، نشيط، واثق من نفسه، مغامر، نشيط، حماسي،
        متفائل. هذا النوع من الشخصيات يكون مهيمنا، مقنعا، و محفزا للآخرين، كما أن من يتسم به يحب التأثير
        على الآخرين، تحمل المسؤولية، تحمل المخاطر، المبادرة، و المنافسة
        يفضل البيئات التنافسية، القيادة، التأثير في الآخرين و البيع.`,
    professions: `يتميز في الأعمال التي تتطلب المبادرة والمخاطرة والتأثير في الآخرين كالعمل التجاري وريادة الأعمال
        والبيع والوظائف القيادية ........الخ`,
    illustration: "e.svg"
  },
  C: {
    title:"المنظم",
    description: `يحب التطابق، يتسم بالكفاءة و الفعالية، عملي، غير متخيل، و غير مرن، منطقيون، منظمون، و منهجيون.
        يميلون للالتفات للجزئيات و يهتمون بها، يقدرون الدقة جيدا، يلتزمون باتباع القواعد و العمل في إطارها.
        يفضل الأعمال التي تتطلب الدقة، تخضع لقوانين و ضوابط معينة يتطلب احترامها، الأعمال المنظمة، و
        التي لا تتسم بالغموض و الإبهام.`,
    professions: `يتميز في الأعمال التي تتطلب المثابرة والدقة والتنظيم كالأعمال الإدارية عموما والمحاسبة`,
    illustration: "c.svg"
  }
};
