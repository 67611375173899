<template>
  <v-col cols="12" md="4" align="center" justify="center">
    <AddButton :title="this.$t('references')" @click="openDialog()" />
    <BaseDraggable v-model="resume.references">
      <DeletableCard
        v-for="(ref, i) in resume.references"
        :key="i"
        :index="i"
        name="references"
      >
        <p class="primary--text">{{ ref.name }} | {{ ref.jobTitle }}</p>
        <p class="primary--text">
          {{ ref.description }} | {{ ref.contactInfo }}
        </p>
      </DeletableCard>
    </BaseDraggable>
    <AddReferenceDialog :showCondition="showDialog" @closed="closeDialog()" />
  </v-col>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "../../store/CreateResumeStore";

import AddButton from "../buttons/AddButton.vue";
import DeletableCard from "../DeletableCard.vue";
import AddReferenceDialog from "../dialogs/AddReferenceDialog.vue";

export default {
  components: { AddButton, DeletableCard, AddReferenceDialog },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    ...mapState(useCreateResumeStore, ["resume"]),
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style></style>
