<template>
  <BaseDialog
    :ltr="selectedLanguage == 'en'"
    :showCondition="show"
    @closed="closeDialog()"
  >
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <v-form ref="form">
        <BaseTextField
          v-model="education.school"
          :label="school"
          :rules="[requiredRule]"
          :placeholder="schoolPlaceholder"
        ></BaseTextField>

        <BaseTextField
          v-model="education.field"
          :rules="[requiredRule]"
          :label="field"
          :placeholder="fieldPlaceholder"
        >
        </BaseTextField>

        <v-checkbox
          class="ma-0 pa-0"
          v-model="education.present"
          :label="currentlyStudying"
        ></v-checkbox>

        <DateField
          v-model="education.startDate"
          :rules="[requiredRule]"
          :label="startDate"
          @changed="(date) => updateStartDate(date)"
        />

        <div v-show="!education.present">
          <DateField
            v-model="education.endDate"
            :label="endDate"
            type="month"
            :rules="education.present ? [] : [requiredRule]"
            @changed="(date) => updateEndDate(date)"
          />
        </div>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn color="accent" rounded @click="validateThenAddEducation()">
        {{ add }}
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import DateField from "../DateField.vue";
import Education from "../../models/Education";
import ResumeValidationMixin from "../ResumeValidationMixin.vue";
import { mapWritableState } from "pinia";
import { useCreateResumeStore } from "../../store/CreateResumeStore";

export default {
  mixins:[ResumeValidationMixin],
  components: { DateField },
  data() {
    return {
      show: this.showCondition,
      education: new Education(),
    };
  },
  props: {
    showCondition: Boolean,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  computed: {
    ...mapWritableState(useCreateResumeStore, ["resume", "selectedLanguage"]),
    add() {
      return this.$t("add");
    },
    title() {
      return this.$t("add_education");
    },
    startDate() {
      return this.$t("start_date");
    },
    endDate() {
      return this.$t("end_date");
    },
    school() {
      return this.$t("school");
    },
    field() {
      return this.$t("field");
    },
    schoolPlaceholder() {
      return this.$t("ph_school");
    },
    fieldPlaceholder() {
      return this.$t("ph_field");
    },
    currentlyStudying() {
      return this.$t("currently_studying");
    },
  },
  methods: {
    updateStartDate(date) {
      this.education.startDate = date;
    },
    updateEndDate(date) {
      this.education.endDate = date;
    },
    reset() {
      this.education = new Education();
      this.$refs.form.reset();
    },
    closeDialog() {
      this.reset();
      this.show = false;
      this.$emit("closed");
    },
    validateThenAddEducation() {
      if (this.$refs.form.validate()) {
        this.resume.educations.push(this.education);
        this.closeDialog();
      }
    },
  },
};
</script>
