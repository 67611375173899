<template>
  <v-col cols="12" md="4" align="center" justify="center">
    <AddButton :title="this.$t('professional_skills')" @click="openDialog()" />
    <BaseDraggable v-model="resume.professionalSkills">
      <DeletableCard
        v-for="(skill, i) in resume.professionalSkills"
        :key="i"
        :index="i"
        name="professionalSkills"
      >
        <ProficiencyRate
          :title="skill.title"
          :proficiency="skill.proficiency"
        />
      </DeletableCard>
    </BaseDraggable>
    <AddProfessionalSkillDialog
      :showCondition="showDialog"
      @closed="closeDialog()"
    />
  </v-col>
</template>

<script>
import { mapWritableState } from "pinia";
import { useCreateResumeStore } from "../../store/CreateResumeStore";

import AddButton from "../buttons/AddButton.vue";
import AddProfessionalSkillDialog from "../dialogs/AddProfessionalSkillDialog.vue";
import ProficiencyRate from "../ProficiencyRate.vue";
import DeletableCard from "../DeletableCard.vue";

export default {
  components: {
    AddButton,
    AddProfessionalSkillDialog,
    ProficiencyRate,
    DeletableCard,
  },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    ...mapWritableState(useCreateResumeStore, ["resume"]),
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style scoped></style>
