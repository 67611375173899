import BasePaginatedCollection from "../../../core/classes/base/BasePaginatedCollection";
import Project from "./Project";

export default class PaginatedProjectsCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    if (paginationResponse != undefined) {
      paginationResponse?.data.map((project) => {
        this.collection.push(new Project(project));
      });
    }
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    // TODO: shall we update the collection ??
    this.collection = new Array();
    paginationResponse?.data.map((project) => {
      this.collection.push(new Project(project));
    });
  }
}
