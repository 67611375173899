<template>
  <FooterItem>
    <template v-slot:content>
      <button @click="$router.push('/')">
        <v-img
          src="@/assets/logos/vector-logo.svg"
          height="180px"
        >
        </v-img>
        </button
    ></template>
  </FooterItem>
</template>

<script>
import FooterItem from "./FooterItem.vue";
export default {
  components: { FooterItem },
};
</script>

<style scoped>
</style>
