<template>
  <v-container  v-if="resume.volunteerExperiences.length > 0" class="py-2">
    <BaseDivider :title="this.$t('volunteer_experiences')" />
    <div v-for="(experience, i) in resume.volunteerExperiences" :key="i">
        <h4>{{ experience.role }}</h4>
        <p class="organization">
          {{ experience.organization }} | {{ $t("from") }} {{ experience.startDate }} {{ $t("to") }}
          {{ experience.present ? $t("present"): experience.endDate }}
        </p>
        <p v-if="experience.description" class="description">
          {{ experience.description }}
        </p>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "@/modules/create_resume/store/CreateResumeStore";
import BaseDivider from "./BaseDivider.vue";

export default {
    computed: {
        ...mapState(useCreateResumeStore, ["resume"]),
    },
    components: { BaseDivider }
};
</script>

<style scoped>
.organization {
  opacity: 0.9;
  display: inline;
}
.description {
  opacity: 0.8;
}
</style>
