<template>
  <v-container class="py-2">
    <BaseDivider :title="this.$t('personal_information')" />
    <v-row justify="space-around">
      <p v-if="this.resume.residence" class="mb-2">
        <v-icon :color="selectedColor">mdi-map-marker</v-icon>
        <span class="mx-2">{{ this.resume.residence }}</span>
      </p>
      <p v-if="this.resume.dateOfBirth" class="mb-2">
        <v-icon :color="selectedColor">mdi-cake</v-icon>
        <span class="mx-2">{{ this.resume.dateOfBirth }}</span>
      </p>
      <p v-if="this.resume.gender" class="mb-2">
        <v-icon :color="selectedColor">mdi-gender-male-female</v-icon>
        <span class="mx-2">{{ $t(this.resume.gender) }}</span>
      </p>
      <p v-if="this.resume.phoneNumber" class="mb-2">
        <v-icon :color="selectedColor">mdi-phone</v-icon>
        <span class="mx-2">{{ this.resume.phoneNumber }}</span>
      </p>
      <p v-if="this.resume.email" class="mb-2">
        <a
          :href="'mailto:' + this.resume.email"
          class="link"
          :style="textColor"
        >
          <v-icon :color="selectedColor">mdi-email</v-icon>
          <span class="mx-2" style="font-size: 14px">{{
            this.resume.email
          }}</span>
        </a>
      </p>
    </v-row>
    <v-row justify="space-around">
      <p v-for="(link, i) in resume.links" :key="i" class="mb-2">
        <v-icon :color="selectedColor">{{ link.icon }}</v-icon>
        <v-btn
         text
          :href="link.url"
          target="_blank"
          class="mx-1 link"
          :style="textColor"
        >
          <span>{{ link.title }}</span>
        </v-btn>
      </p>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "@/modules/create_resume/store/CreateResumeStore";
import BaseDivider from "./BaseDivider.vue";
export default {
    computed: {
        ...mapState(useCreateResumeStore, ["resume", "selectedColor"]),
        textColor() {
            return "color: " + this.selectedColor;
        },
    },
    components: { BaseDivider }
};
</script>

<style scoped>
.link {
  text-decoration: none;
}
</style>
