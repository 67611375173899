<template>
    <v-col>
      <v-row align="center">
        <v-col
          cols="12"
          md="6"
          lg="4"
          align="center"
          v-for="job in paginatedJobs.collection"
          :key="job.id"
        >
          <JobCardItem :job="job" />
        </v-col>
  
        <!-- No data -->
        <v-col>
          <EmptyPaginationCollection :paginatedCollection="paginatedJobs" />
        </v-col>
      </v-row>
      <v-row>
        <!-- previous button -->
        <PaginationPreviousButton
          :paginatedCollection="paginatedJobs"
          :onclick="fetchSuggestionsPreviousPage"
        />
        <!-- Next button -->
        <PaginationNextButton
          class="mr-5"
          :paginatedCollection="paginatedJobs"
          :onclick="fetchSuggestionsNextPage"
        />
      </v-row>
    </v-col>
  </template>
  
  <script>
  import JobCardItem from "../components/JobCardItem.vue";
  import PaginationNextButton from "@/core/components/pagination/PaginationNextButton.vue";
  import PaginationPreviousButton from "@/core/components/pagination/PaginationPreviousButton.vue";
  import EmptyPaginationCollection from "@/core/components/pagination/EmptyPaginationCollection.vue";
  import { mapActions } from "pinia";
  import { useJobOpportunitiesStore } from "../store/JobOpportunitiesStore";
  export default {
    props: {
      paginatedJobs: Object,
    },
    components: {
      JobCardItem,
      PaginationNextButton,
      PaginationPreviousButton,
      EmptyPaginationCollection,
    },
    methods: {
      ...mapActions(useJobOpportunitiesStore, [
        "fetchSuggestionsNextPage",
        "fetchSuggestionsPreviousPage"
      ]),
    },
  };
  </script>
  