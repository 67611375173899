import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import TrainingProgram from "@/core/classes/models/TrainingProgram";
import TrainingProgramQuestion from "@/core/classes/models/TrainingProgramQuestion";
import TrainingProgramApplicationService from "../services/TrainingProgramApplicationService";

export const useApplicationFormStore = defineStore("applicationFormStore", {
  state: () => ({
    fieldsData: {},
    appliedTrainingProgram: new TrainingProgram(),
    trainingProgramQuestions: [],
  }),
  getters: {},
  actions: {
    fetchTrainingProgram(id) {
      return BaseStore.promiseHandler(
        () => TrainingProgramApplicationService.getTrainingProgram(id),
        (data) => {
          this.appliedTrainingProgram = new TrainingProgram(data.data);
        }
      );
    },
    fetchTrainingProgramQuestions() {
      return BaseStore.promiseHandler(
        () => TrainingProgramApplicationService.getTrainingProgramQuestions(),
        (data) => {
          this.trainingProgramQuestions=[];
          data.data.map((tpq) => {
            this.trainingProgramQuestions.push(
              new TrainingProgramQuestion(tpq)
            );
          });
        }
      );
    },
    postTrainingProgramApplication(id, testResultId) {
      return BaseStore.promiseHandler(() =>
        TrainingProgramApplicationService.sendTrainingProgramApplication(id, {
          formContent: this.fieldsData,
          test_result_id: testResultId,
        })
      );
    },
  },
});
