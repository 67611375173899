<template>
  <v-col cols="12" md="4" align="center" justify="center">
    <AddButton :title="this.$t('work_experiences')" @click="openDialog()" />
    <BaseDraggable v-model="resume.workExperiences">
      <DeletableCard
        v-for="(experience, i) in resume.workExperiences"
        :key="i"
        :index="i"
        name="workExperiences"
      >
        <p class="primary--text">
          {{ experience.title }} | {{ experience.company }}
        </p>
        <p v-if="experience.location" class="primary--text">
          {{ experience.location }}
        </p>
        <p v-if="experience.description" class="primary--text">
          {{ experience.description }}
        </p>
        <p class="primary--text">
          {{ experience.startDate }} |
          {{ experience.present ? present : experience.endDate }}
        </p>
      </DeletableCard>
    </BaseDraggable>
    <AddWorkExperienceDialog
      :showCondition="showDialog"
      @closed="closeDialog()"
    />
  </v-col>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "../../store/CreateResumeStore";

import AddButton from "../buttons/AddButton.vue";
import DeletableCard from "../DeletableCard.vue";
import AddWorkExperienceDialog from "../dialogs/AddWorkExperienceDialog.vue";

export default {
  components: { AddButton, DeletableCard, AddWorkExperienceDialog },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    ...mapState(useCreateResumeStore, ["resume"]),
    present() {
      return this.$t("present");
    },
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style></style>
