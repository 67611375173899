var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"bottom":"","open-delay":100},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',{class:[
          'floating-btn-tile',
          {
            'tile-pos-sm': _vm.$vuetify.breakpoint.smAndDown,
            'tile-pos-md': _vm.$vuetify.breakpoint.mdAndUp,
          } ],attrs:{"color":"accent","depressed":"","height":"22","small":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function($event){return _vm.redirectToAboutUsDonation()}}},[_vm._v("تبرّع")]),_c('v-btn',_vm._g({class:[
          'mx-2 floating-btn',
          {
            'pos-sm': _vm.$vuetify.breakpoint.smAndDown,
            'pos-md': _vm.$vuetify.breakpoint.mdAndUp,
          } ],attrs:{"fab":"","dark":"","large":_vm.$vuetify.breakpoint.mdAndUp,"small":_vm.$vuetify.breakpoint.smAndDown,"color":"accent"},on:{"click":function($event){return _vm.redirectToAboutUsDonation()}}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("mdi-hand-heart")])],1)]}}])},[_c('span',[_vm._v("تبرّع")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }