import JobPublisher from "./JobPublisher";
import {
  jobTranslations,
  requiredExperienceLevelCodes
} from "./static_enum_translations/JobEnums";
import Field from "./Field";


export default class JobOpportunity {
  constructor(data) {
    this.id = data?.id;
    this.title = data?.title;
    this.fields = [];
    this.fieldsIds=[];
    data?.fields?.map((field)=>{
      this.fields.push(new Field(field));
      this.fieldsIds.push(field.id);
    });
    this.requiredExperience = data?.required_experience_level;
    this.requiredExperienceLevel =
      requiredExperienceLevelCodes[this.requiredExperience]; //code mapping
    this.minSalary = data?.salary_min;
    this.maxSalary = data?.salary_max;
    this.workLocationType = data?.work_location_type;
    this.workLocation = data?.work_location;
    this.shiftType = data?.shift_type;
    this.workHours = data?.details.work_hours;
    this.minAge = data?.details.age_min;
    this.maxAge = data?.details.age_max;
    this.requiredEducation = data?.details.certificate;
    this.requiredEducationLevel = data?.details.certificate_required_level;
    this.requiredMilitaryServiceStatus =
      data?.details.military_service_termination;
    this.requiredGender = data?.details.gender;
    this.requiredSkills = data?.details.skills_required;
    this.extraRequirements = data?.details.extra_requirements;
    this.responsibilities = data?.details.responsibilities;
    this.publishedAt = data?.accepted_at;
    this.jobPublisher = new JobPublisher(data?.publisher);
    this.userAppliedBefore = data?.user_previously_applied;
    //user sent feedback
    this.sentFeedback=data?.feedback_sent;
  }

  pluckFieldNames(){
    var names=[];
    this.fields.map((field)=>{
      names.push(field.name);
    })
    return names;
  }

  trans(prop) {
    return jobTranslations[prop];
  }
}
