<template>
  <v-col
    class="footer-col text-center"
    cols="12"
    lg="3"
    sm="6"
  >
    <slot name="content"></slot>
  </v-col>
</template>

<script>
export default {};
</script>

<style scoped>
p {
  color: #f3efec;
}

.footer-col {
  padding: 15px 15px;
}
</style>
