<template>
  <v-container class="pt-0 pb-0 mb-3">
    <BaseDivider :title="this.$t('personal_information')" />
    <p v-if="this.resume.residence" class="ma-0 pa-0">
      <v-icon color="white">mdi-map-marker</v-icon>
      <span class="mx-2">{{ this.resume.residence }}</span>
    </p>
    <p v-if="this.resume.dateOfBirth" class="ma-0 pa-0 mt-2">
      <v-icon color="white">mdi-cake</v-icon>
      <span class="mx-2">{{ this.resume.dateOfBirth }}</span>
    </p>
    <p v-if="this.resume.gender" class="ma-0 pa-0 mt-2">
      <v-icon color="white">mdi-gender-male-female</v-icon>
      <span class="mx-2">{{ $t(this.resume.gender) }}</span>
    </p>
    <p v-if="this.resume.phoneNumber" class="ma-0 pa-0 mt-2">
      <v-icon color="white">mdi-phone</v-icon>
      <span class="mx-2">{{ this.resume.phoneNumber }}</span>
    </p>
    <p v-if="this.resume.email" class="ma-0 pa-0 mt-2">
      <a
        :href="'mailto:' + this.resume.email"
        class="link"
        style="color: white"
      >
        <v-icon color="white">mdi-email</v-icon>
        <span class="mx-2" style="font-size: 14px">{{
          this.resume.email
        }}</span>
      </a>
    </p>
    <p v-for="(link, i) in resume.links" :key="i" class="ma-0 pa-0 mt-2">
      <a :href="link.url" class="link" target="_blank" style="color: white">
        <v-icon color="white">{{ link.icon }}</v-icon>
        <span class="mx-2">{{ link.title }}</span>
      </a>
    </p>
  </v-container>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "@/modules/create_resume/store/CreateResumeStore";
import BaseDivider from "./BaseDivider.vue";
export default {
  computed: {
    ...mapState(useCreateResumeStore, ["resume"]),
  },
  components: { BaseDivider },
};
</script>

<style>
.link {
  color: white;
  text-decoration: none;
}
</style>
