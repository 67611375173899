<template>
  <v-navigation-drawer
    color="lightNeutral"
    class="d-lg-none d-xl-flex"
    height="100%"
    v-model="isDrawerVisible"
    overlay
    clipped
    temporary
    app
    :right="true"
  >
    <!--logo-->
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-img
            src="@/assets/logos/vector-logo.svg"
            max-width="120"
          ></v-img>
          <!--welcome phrase for authenticated user-->
          <h3 v-if="isAuthenticated" class="accent--text mt-5">
            أهلاً بك {{ username }} !
          </h3>
        </v-list-item-content>
      </v-list-item>
      <!--TABS-->
      <v-list-item
        router
        v-for="tab in tabList"
        :key="tab.title"
        :to="tab.link"
      >
        <v-list-item-content>
          <v-list-item-title> {{ tab.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!--Authentication buttons/user profile-->
    <template v-slot:append>
      <v-list>
      <template v-if="!isAuthenticated">
        <v-list-item>
          <v-list-item-content>
            <SignUpButton />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <SignInButton />
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item>
          <v-list-item-content>
            <ProfileButton />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <SignOutButton />
          </v-list-item-content>
        </v-list-item>
      </template>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { useGlobalStore } from "@/store/GlobalStore";
import { useAuthStore } from "@/modules/auth/store/AuthStore";
import { mapWritableState } from "pinia";
import TabsList from "./TabsList";
import SignUpButton from "./components/SignUpButton";
import SignInButton from "./components/SignInButton";
import SignOutButton from "./components/SignOutButton";
import ProfileButton from "./components/ProfileButton";

export default {
  components: {
    SignUpButton,
    SignInButton,
    SignOutButton,
    ProfileButton,
  },
  data() {
    return {
      tabList: TabsList,
    };
  },
  computed: {
    ...mapWritableState(useGlobalStore, ["isDrawerVisible"]),
    ...mapWritableState(useAuthStore, ["isAuthenticated","username"]),
  },
};
</script>

<style scoped>
</style>
