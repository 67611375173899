<template>
  <BaseDecoratorWrapper>
    <v-container>
      <v-container>
        <BaseTransitionWrapper>
          <v-row align="center" justify="center">
            <v-col align="center" cols="12" md="5">
              <h1 class="secondary--text">مشاريع البركة المتميزة</h1>
              <p class="mt-5">
                معرض مشاريع خريجي جمعية البركة في برامجها التدريبية
              </p>
            </v-col>
            <v-col align="center" md="5" class="d-none d-md-inline">
              <v-img
                max-height="500px"
                contain
                src="@/assets/illustrations/projects_fair/welcoming.png"
              ></v-img>
            </v-col> </v-row
        ></BaseTransitionWrapper>
      </v-container>

      <!-- Projects Fair -->
      <v-container class="mt-5">
        <v-row justify="center">
          <v-col cols="12" md="10">
            <!--filter tags-->
            <v-row justify="center" class="mb-5">
              <BaseFilterChips
                @filtered="
                  (selectedTag) => setFilterStateAndRefetch(selectedTag)
                "
                :tags="tags"
              />
            </v-row>

            <!-- Projects Cards -->
            <v-row>
              <BaseCard
                v-for="project in paginatedProjects.collection"
                :key="project.id"
                :obj="project"
                :isNews="false"
              />
            </v-row>

            <!-- No data -->
            <EmptyPaginationCollection
              :paginatedCollection="paginatedProjects"
            />

            <v-row>
              <!-- previous button -->
              <PaginationPreviousButton
                :paginatedCollection="paginatedProjects"
                :onclick="fetchPreviousPage"
              />
              <!-- Next button -->
              <PaginationNextButton
                class="mr-5"
                :paginatedCollection="paginatedProjects"
                :onclick="fetchNextPage"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </BaseDecoratorWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useProjectsFairStore } from "../store/ProjectsFairStore";
import BaseCard from "@/core/components/news_and_projects/BaseCard.vue";
import PaginationNextButton from "@/core/components/pagination/PaginationNextButton.vue";
import PaginationPreviousButton from "@/core/components/pagination/PaginationPreviousButton.vue";
import EmptyPaginationCollection from "@/core/components/pagination/EmptyPaginationCollection.vue";

const ProjectsFairStore = useProjectsFairStore();

export default {
  components: {
    BaseCard,
    PaginationNextButton,
    PaginationPreviousButton,
    EmptyPaginationCollection,
  },
  computed: {
    ...mapState(useProjectsFairStore, ["paginatedProjects", "tags"]),
  },
  methods: {
    ...mapActions(useProjectsFairStore, [
      "fetchProjectsTags",
      "fetchPaginatedProjects",
      "setFilterStateAndRefetch",
      "fetchNextPage",
      "fetchPreviousPage",
    ]),
  },
  mounted() {
    this.fetchProjectsTags()
      .then()
      .catch((err) => err);
    this.fetchPaginatedProjects()
      .then()
      .catch((err) => err);
  },
  destroyed() {
    ProjectsFairStore.$reset();
  },
};
</script>

<style></style>
