<template>
  <BaseDecoratorWrapper>
    <v-container>
      <v-container>
        <BaseTransitionWrapper>
          <v-row align="center" justify="center">
            <v-col align="center" cols="12" md="4">
              <h1 class="secondary--text">أخبار البركة</h1>
              <p class="mt-5">آخر الأخبار والمستجدات</p>
            </v-col>
            <v-col align="center" md="4" class="d-none d-md-inline">
              <v-img
                max-width="400px"
                max-height="500px"
                contain
                src="@/assets/illustrations/news/news.png"
              ></v-img>
            </v-col> </v-row
        ></BaseTransitionWrapper>
      </v-container>
      <v-container>
        <v-row justify="center">
          <v-col cols="12" md="10">
            <v-row>
              <BaseCard
                v-for="news in paginatedNews.collection"
                :key="news.id"
                :obj="news"
                :isNews="true"
              />
            </v-row>

            <!-- No data -->
            <EmptyPaginationCollection :paginatedCollection="paginatedNews" />
            <v-row>
              <!-- previous button -->
              <PaginationPreviousButton
                :paginatedCollection="paginatedNews"
                :onclick="fetchPreviousPage"
              />
              <!-- Next button -->
              <PaginationNextButton
                class="mr-5"
                :paginatedCollection="paginatedNews"
                :onclick="fetchNextPage"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </BaseDecoratorWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useNewsStore } from "../store/NewsStore";

import BaseCard from "@/core/components/news_and_projects/BaseCard.vue";
import PaginationNextButton from "@/core/components/pagination/PaginationNextButton.vue";
import EmptyPaginationCollection from "@/core/components/pagination/EmptyPaginationCollection.vue";
import PaginationPreviousButton from "@/core/components/pagination/PaginationPreviousButton.vue";

const NewsStore = useNewsStore();

export default {
  components: {
    BaseCard,
    PaginationNextButton,
    EmptyPaginationCollection,
    PaginationPreviousButton,
  },
  computed: {
    ...mapState(useNewsStore, ["paginatedNews"]),
  },
  methods: {
    ...mapActions(useNewsStore, [
      "fetchPaginatedNews",
      "fetchNextPage",
      "fetchPreviousPage",
    ]),
  },
  mounted() {
    this.fetchPaginatedNews()
      .then()
      .catch((err) => err);
  },
  destroyed() {
    NewsStore.$reset();
  },
};
</script>

<style scoped>
h1 {
  font-size: 50px;
}
</style>
