<script>
export default {
  data() {
    return {
      requiredRule: (v) => !!v || this.requiredErrorMessage,
    };
  },
  computed:{
    requiredErrorMessage(){
        return this.$t("required_field")
    }
  }
};
</script>
