<template>
  <v-container class="mt-10">
    <v-row align="center" justify="center">
      <v-col cols="10">
        <v-row justify="space-between" align="center">
          <ServiceItem
            v-for="(service, index) in services"
            :key="index"
            :title="service.title"
            :description="service.description"
            :imgName="service.imgName"
            :path="service.path"
          ></ServiceItem>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ServiceItem from "./ServiceItem.vue";

export default {
  components: {
    ServiceItem,
  },
  data() {
    return {
      services: [
        {
          title: "استشارة تحديد المسار المهني",
          description:
            "تقديم استشارات تحديد المسار الوظيفي لمساعدة الأيتام والفقراء على اختيار المجال أو المهنة الأنسب لهم بطريقة عملية",
          imgName: "career_path_counseling",
          path: "/career-aptitude-test",
        },
        {
          title: "المنح التدريبية",
          description:
            "تقديم تدريبات مجانية لتزويد المسجلين بالخبرات والمهارات اللازمة للنجاح في العمل",
          imgName: "training_programs",
          path: "/training-programs",
        },
        {
          title: "فرص العمل",
          description:
            "المساعدة في تأمين عمل بعد التدريب أو تمويل المتدرب لفتح عمل خاص",
          imgName: "job_opportunities",
          path: "/job-opportunities",
        },
        {
          title: "إنشاء السيرة الذاتية",
          description:
            "تقديم خدمة إنشاء السيرة الذاتية، من أجل مساعدة الراغبين بالتقدم إلى فرص العمل على إنشاء سيرة ذاتية احترافية",
          imgName: "create_resume",
          path: "/create-resume",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped></style>
