<template>
  <v-stepper v-model="step">
    <v-stepper-header>
      <v-stepper-step color="accent" editable step="1" id="personal-info-step-btn">
        <small class="mx-2">
          {{ this.$t("personal_information") }}
        </small>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step editable color="accent" step="2" id="skills-languages-step-btn">
        <small class="mx-2">
          {{ this.$t("skills_and_languages") }}
        </small>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step editable color="accent" step="3" id="education-experience-step-btn">
        <small class="mx-2">
          {{ this.$t("education_and_experiences") }}
        </small>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step editable color="accent" step="4" id="certifications-projects-step-btn">
        <small class="mx-2">
          {{ this.$t("certifications_and_projects") }}
        </small>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step editable color="accent" step="5" id="links-references-step-btn" >
        <small class="mx-2">
          {{ this.$t("links_and_references") }}
        </small>
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <h3 class="secondary--text text-center my-3 d-md-none">
          {{ this.$t("personal_information") }}
        </h3>
        <PersonalInfoStep ref="form" />
      </v-stepper-content>

      <v-stepper-content step="2">
        <h3 class="secondary--text text-center my-3 d-md-none">
          {{ this.$t("skills_and_languages") }}
        </h3>
        <SkillsAndLanguagesStep />
      </v-stepper-content>

      <v-stepper-content step="3">
        <h3 class="secondary--text text-center my-3 d-md-none">
          {{ this.$t("education_and_experiences") }}
        </h3>
        <EducationAndExperiencesStep />
      </v-stepper-content>

      <v-stepper-content step="4">
        <h3 class="secondary--text text-center my-3 d-md-none">
          {{ this.$t("certifications_and_projects") }}
        </h3>
        <CertificationsAndProjectsStep />
      </v-stepper-content>

      <v-stepper-content step="5">
        <h3 class="secondary--text text-center my-3 d-md-none">
          {{ this.$t("links_and_references") }}
        </h3>
        <LinksAndReferencesStep />
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { mapWritableState } from "pinia";
import { useCreateResumeStore } from "../../store/CreateResumeStore";
import PersonalInfoStep from "./steps/PersonalInfoStep.vue";
import SkillsAndLanguagesStep from "./steps/SkillsAndLanguagesStep.vue";
import LinksAndReferencesStep from "./steps/LinksAndReferencesStep.vue";
import EducationAndExperiencesStep from "./steps/EducationAndExperiencesStep.vue";
import CertificationsAndProjectsStep from "./steps/CertificationsAndProjectsStep.vue";

export default {
  components: {
    PersonalInfoStep,
    SkillsAndLanguagesStep,
    LinksAndReferencesStep,
    EducationAndExperiencesStep,
    CertificationsAndProjectsStep,
  },
  computed: {
    ...mapWritableState(useCreateResumeStore, ["step"]),
  },
};
</script>
