<template>
  <v-container>
    <DetailsPage v-if="!fetchingData" :obj="news" :isNews="true" />
  </v-container>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useNewsStore } from "../store/NewsStore";

import DetailsPage from "@/core/components/news_and_projects/DetailsPage.vue";

export default {
  components: { DetailsPage },
  data() {
    return {
      fetchingData: true,
    };
  },
  computed: {
    ...mapState(useNewsStore, ["news"]),
  },
  methods: {
    ...mapActions(useNewsStore, ["fetchNews"]),
  },
  mounted() {
    this.fetchNews(this.$route.params.id)
      .then(() => (this.fetchingData = false))
      .catch((err) => console.error(err));
  },
};
</script>

<style scoped></style>
