<template>
  <BaseDialog
    :ltr="selectedLanguage == 'en'"
    :showCondition="show"
    @closed="closeDialog()"
  >
    <template v-slot:title> {{ title }} </template>
    <template v-slot:body>
      <v-form ref="form">
        <BaseTextField
          v-model="reference.name"
          :rules="[requiredRule]"
          :label="name"
          :placeholder="name"
        >
        </BaseTextField>

        <BaseTextField
          v-model="reference.jobTitle"
          :rules="[requiredRule]"
          :label="jobTitle"
          :placeholder="jobTitlePlaceholder"
        >
        </BaseTextField>

        <BaseSelect
          :rules="[requiredRule]"
          v-model="reference.description"
          :items="referenceDescriptionOptions"
          :label="referenceDescription"
          :placeholder="referenceDescription"
        >
          <template v-slot:item="{ item }">
            <span> {{ item }} </span>
          </template>
          <template #selection="{ item }">
            <span> {{ item }} </span>
          </template>
        </BaseSelect>

        <BaseTextField
          v-model="reference.contactInfo"
          :rules="[requiredRule]"
          :label="contactInfo"
          :placeholder="contactInfoPlaceholder"
        >
        </BaseTextField>
      </v-form>
    </template>

    <template v-slot:actions>
      <v-btn color="accent" rounded @click="validateThenAddReference()">
        {{ add }}
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import { mapWritableState } from "pinia";
import { useCreateResumeStore } from "../../store/CreateResumeStore";
import ResumeValidationMixin from "../ResumeValidationMixin.vue";
import Reference from "../../models/Reference";

export default {
  mixins:[ResumeValidationMixin],
  data() {
    return {
      show: this.showCondition,
      reference: new Reference(),
    };
  },
  props: {
    showCondition: Boolean,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  computed: {
    ...mapWritableState(useCreateResumeStore, ["resume","selectedLanguage"]),
    title() {
      return this.$t("add_reference");
    },
    add() {
      return this.$t("add");
    },
    jobTitle() {
      return this.$t("job_title");
    },
    name() {
      return this.$t("name");
    },
    referenceDescription() {
      return this.$t("reference_description");
    },
    referenceDescriptionOptions() {
      return this.$t("reference_description_options");
    },
    contactInfo() {
      return this.$t("contact_info");
    },
    contactInfoPlaceholder() {
      return this.$t("ph_contact_info");
    },
    jobTitlePlaceholder() {
      return this.$t("ph_job_title");
    },
  },
  methods: {
    reset() {
      this.reference = new Reference();
      this.$refs.form.reset();
    },
    closeDialog() {
      this.reset();
      this.show = false;
      this.$emit("closed");
    },
    validateThenAddReference() {
      if (this.$refs.form.validate()) {
        this.resume.references.push(this.reference);
        this.closeDialog();
      }
    },
  },
};
</script>
