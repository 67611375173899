<template>
  <v-card color="lightSecondary" rounded="xl">
    <!--JOB PUBLISHER PROFILE & ACTION BTNs-->
    <v-card-title>
      <v-container>
        <v-row>
          <v-col align-self="center" cols="12" md="6" class="secondary--text">
            <v-avatar color="white" size="100">
              <v-img
                v-if="job.jobPublisher.logoURL"
                contain
                :src="job.jobPublisher.logoURL"
                class="rounded"
              ></v-img>
              <v-icon v-else x-large color="lightSecondary">mdi-domain</v-icon>
            </v-avatar>

            <h3 class="secondary--text break-word">
              {{ job.jobPublisher.name }}
            </h3>

            <small class="white--text">{{ job.publishedAt }}</small>
          </v-col>
          <v-col align-self="center">
            <div>
              <v-btn
                id="apply-btn"
                v-if="!job.userAppliedBefore"
                @click="checkAuthThenOpenApplicationDialog()"
                color="accent"
                rounded
                >التقدم للوظيفة</v-btn
              >
              <v-chip v-else color="accent" rounded>
                تقدمت للفرصة <v-icon dark>mdi-check</v-icon></v-chip
              >
            </div>
            <div class="mt-3">
              <v-btn
                @click="checkAuthThenOpenRatingDialog()"
                v-if="!job.sentFeedback"
                color="accent"
                class="px-5"
                rounded
                >أرسل ملاحظة
              </v-btn>
              <v-chip v-else color="accent" class="px-5" rounded>
                تم إرسال ملاحظتك على الفرصة
                <v-icon dark>mdi-check</v-icon></v-chip
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <!--JOB OPPORTUNITY DETAILS-->
    <v-card-text>
      <v-card class="py-5" rounded="xl" elevation="5">
        <v-container>
          <v-row>
            <v-col align="start">
              <v-row class="mb-10 text">
                <h2 class="secondary--text mx-3 my-2">{{ job.title }}</h2>
                <v-chip class="mx-2" color="primary">{{ job.pluckFieldNames().toString() }}</v-chip>
              </v-row>
              <div>
                <v-icon color="primary">mdi-poll</v-icon>
                <span class="primary--text"> مستوى الخبرة المطلوبة: </span>
                {{ job.requiredExperienceLevel }}
              </div>
              <div>
                <v-icon color="primary">mdi-clock-outline</v-icon>
                <span class="primary--text"> نوع الدوام : </span>
                {{ job.trans(job.shiftType) }} ({{ job.workHours }} ساعة)
              </div>
              <div v-if="job.minSalary && job.maxSalary">
                <v-icon color="primary">mdi-cash-multiple</v-icon>
                <span class="primary--text"> مجال الراتب : </span>
                ({{ formatCurrency(job.minSalary) }} -
                {{ formatCurrency(job.maxSalary) }} )
              </div>
              <div v-if="job.minAge && job.maxAge">
                <v-icon color="primary">mdi-account-supervisor</v-icon>
                <span class="primary--text"> مجال العمر : </span>
                ({{ job.minAge }} - {{ job.maxAge }}سنة )
              </div>
              <div>
                <v-icon color="primary">mdi-certificate-outline</v-icon>
                <span class="primary--text"> التحصيل العلمي المطلوب: </span>
                {{ job.requiredEducation }} ({{
                  job.trans(job.requiredEducationLevel)
                }})
              </div>

              <div>
                <v-icon color="primary">mdi-list-status</v-icon>
                <span class="primary--text"> إنهاء خدمة العلم: </span>
                ({{ job.trans(job.requiredMilitaryServiceStatus) }})
              </div>

              <div>
                <v-icon color="primary">mdi-gender-male-female</v-icon>
                <span class="primary--text"> الجنس المطلوب: </span>
                ({{ job.trans(job.requiredGender) }})
              </div>

              <div
                v-if="
                  job.workLocationType == 'on_site' ||
                  job.workLocationType == 'hybrid'
                "
              >
                <v-icon color="primary">mdi-map-marker</v-icon>
                <span class="primary--text"> موقع العمل: </span>
                {{ job.workLocation }} ({{ job.trans(job.workLocationType) }})
              </div>
              <div v-else>
                <v-icon color="primary">mdi-map-marker</v-icon>
                <span class="primary--text"> موقع العمل: </span>
                 عمل عن بعد
              </div>
              <div>
                <h4 class="secondary--text mt-5">المهارات المطلوبة:</h4>
                <ul class="mr-7">
                  <li v-for="skill in job.requiredSkills" :key="skill">
                    {{ skill }}
                  </li>
                </ul>
              </div>

              <div>
                <h4 class="secondary--text">تفاصيل الفرصة:</h4>
                <div v-if="job.responsibilities">
                  <span class="primary--text">  المسؤوليات: </span>
                   {{ job.responsibilities }}
                </div>
                <div v-if="job.extraRequirements">
                  <span class="primary--text"> متطلبات إضافية : </span>
                  {{ job.extraRequirements }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-card-text>
    <!--JOB PUBLISHER DETAILS-->
    <v-container v-if="job.jobPublisher.isVisible">
      <v-row>
        <v-col cols="12" md="10" align="start" class="secondary--text">
          <div>
            <h4>
              <v-icon color="primary">mdi-information</v-icon> عن ناشر فرصة
              العمل
            </h4>
            <div class="mr-3">
              {{ job.jobPublisher.about }}
            </div>
          </div>
          <div>
            <v-icon color="primary">mdi-briefcase-variant-outline</v-icon>
            المجال:
            {{ job.jobPublisher.field }}
          </div>
          <div>
            <v-icon color="primary">mdi-map-marker</v-icon> الموقع:
            {{ job.jobPublisher.location }}
          </div>
          <div>
            <v-icon color="primary">mdi-email-outline</v-icon> البريد
            الالكتروني:
            {{ job.jobPublisher.email }}
          </div>
          <div>
            <v-icon color="primary">mdi-cellphone</v-icon> رقم الهاتف:
            {{ job.jobPublisher.phoneNumber }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!--DIALOGS (application/notes)-->
    <JobApplicationDialog
      :showCondition="showApplicationDialog"
      @closed="closeApplicationDialog()"
    />
    <JobRatingDialog
      :showCondition="showRatingDialog"
      :jobId="job.id"
      @closed="closeRatingDialog()"
    />
  </v-card>
</template>

<script>
import CurrencyFormattingMixin from "@/core/mixins/CurrencyFormattingMixin.vue";
import JobApplicationDialog from "./JobApplicationDialog.vue";
import JobRatingDialog from "./JobRatingDialog.vue";
import { useAuthStore } from "@/modules/auth/store/AuthStore";
import { mapActions, mapState } from "pinia";

export default {
  props: {
    job: Object,
  },
  data() {
    return {
      showApplicationDialog: false,
      showRatingDialog: false,
    };
  },
  components: { JobApplicationDialog, JobRatingDialog },
  computed: {
    ...mapState(useAuthStore, ["isAuthenticated"]),
  },
  mixins: [CurrencyFormattingMixin],
  methods: {
    ...mapActions(useAuthStore, ["changeLoginDialogState"]),
    checkAuthThenOpenApplicationDialog() {
      //check auth first then open it
      if (!this.isAuthenticated) {
        this.changeLoginDialogState(true, false);
      } else this.showApplicationDialog = true;
    },
    closeApplicationDialog() {
      this.showApplicationDialog = false;
    },
    checkAuthThenOpenRatingDialog() {
      //check auth first then open it
      if (!this.isAuthenticated) {
        this.changeLoginDialogState(true, false);
      } else this.showRatingDialog = true;
    },
    closeRatingDialog() {
      this.showRatingDialog = false;
    },
  },
};
</script>

<style scoped>
.break-word {
  word-break: break-word;
}
</style>
