<template>
  <BaseDecoratorWrapper>
    <OurVision/>
    <OurServices />
    <HowToDonate />
  </BaseDecoratorWrapper>
</template>

<script>
import OurVision from "../components/OurVision.vue";
import OurServices from "../components/OurServices.vue";
import HowToDonate from "../components/HowToDonate.vue";
export default {
  components: { OurVision, OurServices, HowToDonate },
};
</script>

<style scoped></style>
