<template>
  <BaseDialog
    :ltr="selectedLanguage == 'en'"
    :showCondition="show"
    @closed="closeDialog()"
  >
    <template v-slot:title> {{ title }} </template>
    <template v-slot:body>
      <v-form ref="form">
        <v-row>
          <v-col cols="12" sm="3">
            <BaseSelect
              v-model="link.icon"
              :rules="[requiredRule]"
              :items="icons"
              :label="icon"
              :placeholder="icon"
            >
              <template v-slot:item="{ item }">
                <v-icon> {{ item }} </v-icon>
              </template>
              <template #selection="{ item }">
                <v-icon> {{ item }} </v-icon>
              </template>
            </BaseSelect>
          </v-col>
          <v-col>
            <BaseTextField
              v-model="link.title"
              :rules="[requiredRule]"
              :label="linkName"
              :placeholder="linkNamePlaceholder"
            >
            </BaseTextField>
          </v-col>
        </v-row>

        <BaseTextField
          v-model="link.url"
          :rules="[requiredRule]"
          :label="theLink"
          :placeholder="theLinkPlaceholder"
        >
        </BaseTextField>
      </v-form>
    </template>

    <template v-slot:actions>
      <v-btn color="accent" rounded @click="validateThenAddLink()">
        {{ add }}
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import { mapWritableState } from "pinia";
import { useCreateResumeStore } from "../../store/CreateResumeStore";
import Link from "../../models/Link";
import BaseDialog from "@/core/base/dialogs/BaseDialog.vue";
import BaseTextField from "@/core/base/inputs/BaseTextField.vue";
import ResumeValidationMixin from "../ResumeValidationMixin.vue";

export default {
  mixins:[ResumeValidationMixin],
  components: { BaseDialog, BaseTextField },
  data() {
    return {
      show: this.showCondition,
      link: new Link(),
      icons: [
        "mdi-web",
        "mdi-facebook",
        "mdi-linkedin",
        "mdi-twitter",
        "mdi-instagram",
        "mdi-pinterest",
        "mdi-github",
        "mdi-gitlab",
      ],
    };
  },
  props: {
    showCondition: Boolean,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  computed: {
    ...mapWritableState(useCreateResumeStore, ["resume", "selectedLanguage"]),
    title() {
      return this.$t("add_link");
    },
    add() {
      return this.$t("add");
    },
    icon() {
      return this.$t("icon");
    },
    theLink() {
      return this.$t("link");
    },
    linkName() {
      return this.$t("link_name");
    },
    linkNamePlaceholder() {
      return this.$t("ph_link_name");
    },
    theLinkPlaceholder() {
      return this.$t("ph_link");
    },
  },
  methods: {
    reset() {
      this.link = new Link();
      this.$refs.form.reset();
    },
    closeDialog() {
      this.reset();
      this.show = false;
      this.$emit("closed");
    },
    validateThenAddLink() {
      if (this.$refs.form.validate()) {
        this.resume.links.push(this.link);
        this.closeDialog();
      }
    },
  },
};
</script>
