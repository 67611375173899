<template>
  <v-form :ref="formReference">
    <div v-for="field in fields" :key="field.id" class="my-6">
      <!--case of text field-->
      <BaseTextField
        v-if="field.type == 'text_field'"
        :label="field.label"
        :name="field.label"
        :placeholder="field.placeholder ? field.placeholder : field.label"
        persistent-placeholder
        hide-details="auto"
        outlined
        :rules="field.isRequired ? [requiredRule] : []"
        @input="updateFieldsData($event, fieldsData, field.id)"
      ></BaseTextField>

      <!--case of text area-->
      <BaseTextArea
        v-if="field.type == 'text_area'"
        :rules="field.isRequired ? [requiredRule] : []"
        :label="field.label"
        :placeholder="field.placeholder ? field.placeholder : field.label"
        persistent-placeholder
        hide-details="auto"
        outlined
        :name="field.label"
        @input="updateFieldsData($event, fieldsData, field.id)"
      ></BaseTextArea>

      <!--case of selection field-->
      <v-select
        v-if="field.type == 'selection'"
        :items="field.answers"
        :rules="field.isRequired ? [requiredRule] : []"
        :label="field.label"
        :placeholder="field.placeholder ? field.placeholder : field.label"
        persistent-placeholder
        hide-details="auto"
        outlined
        :name="field.label"
        @input="updateFieldsData($event, fieldsData, field.id)"
      >
      </v-select>

      <!--case of radio field-->
      <div v-if="field.type == 'radio'">
        {{ field.label }}
        <v-radio-group
          :rules="field.isRequired ? [requiredRule] : []"
          @change="updateFieldsData($event, fieldsData, field.id)"
        >
          <v-radio
            v-for="answer in field.answers"
            :key="answer"
            :label="answer"
            :value="answer"
          ></v-radio>
        </v-radio-group>
      </div>

      <!--case of date field-->
      <v-menu
        v-if="field.type == 'date'"
        ref="menu1"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <BaseTextField
            hide-details="auto"
            :rules="field.isRequired ? [requiredRule] : []"
            :label="field.label"
            :placeholder="field.placeholder ? field.placeholder : field.label"
            persistent-placeholder
            outlined
            prepend-inner-icon="mdi-calendar"
            @change="updateFieldsData($event, fieldsData, field.id)"
            v-bind="attrs"
            v-on="on"
            :value="fieldsData[field.id]"
          ></BaseTextField>
        </template>
        <v-date-picker
          @change="updateFieldsData($event, fieldsData, field.id)"
          no-title
        ></v-date-picker>
      </v-menu>

      <!--red note message of a field-->
      <small v-if="field.note" class="error--text">{{ field.note }}</small>
    </div>
  </v-form>
</template>

<script>
import { mapWritableState } from "pinia";
import { useApplicationFormStore } from "../store/ApplicationFormStore";
import { EventBus } from "@/main";

export default {
  props: {
    fields: Array,
    formReference: String,
  },
  methods: {
    updateFieldsData(event, obj, prop) {
      this.$set(obj, prop, event);
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapWritableState(useApplicationFormStore, ["fieldsData"]),
  },
  watch: {
    fieldsData: {
      deep: true,
      handler() {
        EventBus.$emit("formChanged");
      },
    },
  },
};
</script>
