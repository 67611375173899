<template>
  <BaseDecoratorWrapper>
    <v-container>
      <v-row justify="center">
        <v-col cols="11" align-items="center">
          <v-row class="mb-1 primary--text" align="center">
            هل أنت مهتم بنشر فرص عمل؟
            <v-btn text>
              <!--TODO change link according to publishers' system subdomain/port-->
              <a :href="publishersSystemRegisterURL" target="_blank">
                <span class="accent--text text-decoration-underline"
                  >اضغط هنا</span
                >
              </a>
            </v-btn>
          </v-row>

          <v-row class="mb-5 primary--text" align="center">
            هل أنت مدرب ومهتم بالانضمام الى مدربي جمعية البركة؟
            <v-btn
              text
              color="accent"
              class="text-decoration-underline"
              @click.prevent="openTrainerRegisterDialog()"
              >تقدم بسيرتك الذاتية</v-btn
            >
          </v-row>

          <v-row>
            <v-text-field
              v-model="searchKey"
              placeholder="ابحث عن فرص العمل...."
              filled
              clearable
              dense
              prepend-inner-icon="mdi-magnify"
              rounded
              background-color="white"
              style="max-width: 600px"
              @input="debounceSearch()"
            ></v-text-field>

            <v-btn rounded text @click="openFilterDialog()">
              <v-badge overlap dot color="accent" v-if="filtersAreApplied">
                <v-icon color="primary" x-large>mdi-filter</v-icon>
              </v-badge>
              <v-icon v-else color="primary" x-large>mdi-filter</v-icon>
            </v-btn>
            <v-btn
              v-if="isAuthenticated"
              to="/job-opportunities/my-applied-jobs"
              color="primary"
              rounded
              >فرص العمل التي تقدمت لها</v-btn
            >
          </v-row>
          <!--TABS-->
          <v-tabs
            class="my-5"
            v-model="tab"
            fixed-tabs
            show-arrows
            background-color="transparent"
            centered
          >
            <v-tab href="#all-jobs"> جميع فرص العمل </v-tab>
            <v-tab v-if="isAuthenticated" href="#suggested-jobs">
              فرص العمل المقترحة
            </v-tab>
          </v-tabs>

          <v-tabs-items class="transparent" v-model="tab">
            <v-tab-item value="all-jobs">
              <AllJobItems :paginatedJobs="paginatedJobs" />
            </v-tab-item>
            <v-tab-item v-if="isAuthenticated" value="suggested-jobs">
              <UserRelatedJobItems :paginatedJobs="paginatedJobSuggestions" />
            </v-tab-item>
          </v-tabs-items>
          <!--END OF TABS-->
        </v-col>
      </v-row>
    </v-container>

    <!-- Dialogs -->
    <TrainerRegisterRequestDialog
      :showCondition="showTrainerRegisterDialog"
      @closed="closeTrainerRegisterDialog()"
    />
    <JobFiltersDialog
      ref="filterDialog"
      :showCondition="showFilterDialog"
      @closed="closeFilterDialog()"
    />
  </BaseDecoratorWrapper>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import JobFiltersDialog from "../components/JobFiltersDialog.vue";
import TrainerRegisterRequestDialog from "../components/TrainerRegisterRequestDialog.vue";
import AllJobItems from "../components/AllJobItems.vue";
import UserRelatedJobItems from "../components/UserRelatedJobItems.vue";
import { useJobOpportunitiesStore } from "../store/JobOpportunitiesStore";
import { useAuthStore } from "@/modules/auth/store/AuthStore";
import { Notify } from "@/core/classes/notify/Notify";

const jobOpportunitiesStore = useJobOpportunitiesStore();

export default {
  components: {
    JobFiltersDialog,
    AllJobItems,
    UserRelatedJobItems,
    TrainerRegisterRequestDialog,
  },
  mounted() {
    this.fetchJobs()
      .then()
      .catch((err) => console.error(err));
    if (this.isAuthenticated) {
      this.fetchJobSuggestions()
        .then()
        .catch((err) => console.error(err));
    }
  },
  data() {
    return {
      tab: null,
      showFilterDialog: false,
      searchTimeout: null,
      showTrainerRegisterDialog: false,

    };
  },
  computed: {
    ...mapWritableState(useJobOpportunitiesStore, ["searchKey"]),
    ...mapState(useJobOpportunitiesStore, [
      "filtersAreApplied",
      "paginatedJobs",
      "paginatedJobSuggestions",
    ]),
    ...mapState(useAuthStore, ["isAuthenticated", "email"]),
    publishersSystemRegisterURL() {
      return process.env.VUE_APP_PUBLISHERS_APP_BASE_URL + "/register";
    },
  },
  methods: {
    ...mapActions(useAuthStore, ["changeEmailDialogState"]),

    openTrainerRegisterDialog() {
      if (!this.isAuthenticated)
        Notify.showMessage(
          "warning",
          "يجب عليك تسجيل الدخول من أجل القيام بهذا الإجراء"
        );
      else if (!this.email) {
        this.changeEmailDialogState(true, false);
      } else this.showTrainerRegisterDialog = true;
    },
    closeTrainerRegisterDialog() {
      this.showTrainerRegisterDialog = false;
    },
    openFilterDialog() {
      this.showFilterDialog = true;
    },
    closeFilterDialog() {
      this.showFilterDialog = false;
    },
    resetFiltersAndResetDialogFilters() {
      this.$refs.filterDialog.mapStoreFilterWithState();
      this.resetFilters();
    },
    ...mapActions(useJobOpportunitiesStore, [
      "fetchJobs",
      "fetchJobSuggestions",
      "resetFilters",
      "sendSearchFetch",
    ]),
    sendSearchKeyAndRefetch() {
      this.sendSearchFetch();
    },
    debounceSearch() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.sendSearchKeyAndRefetch();
      }, 500);
    },
  },
  destroyed() {
    jobOpportunitiesStore.$reset();
  },
};
</script>
