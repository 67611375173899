var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDialog',{attrs:{"showCondition":_vm.show},on:{"closed":function($event){return _vm.closeDialog()}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" تصفية فرص العمل "),_c('v-icon',[_vm._v("mdi-filter")])]},proxy:true},{key:"body",fn:function(){return [_c('v-select',{attrs:{"rounded":"","dense":"","chips":"","clearable":"","item-value":"value","items":_vm.shiftTypes,"label":"نوع الدوام","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.label)+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(item.label))])]}}]),model:{value:(_vm.shiftTypeFilter),callback:function ($$v) {_vm.shiftTypeFilter=$$v},expression:"shiftTypeFilter"}}),_c('v-select',{attrs:{"rounded":"","dense":"","chips":"","clearable":"","item-value":"value","items":_vm.experienceLevels,"label":"مستوى الخبرة ","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.label)+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(item.label))])]}}]),model:{value:(_vm.experienceLevelFilter),callback:function ($$v) {_vm.experienceLevelFilter=$$v},expression:"experienceLevelFilter"}}),_c('v-select',{attrs:{"rounded":"","dense":"","chips":"","clearable":"","item-value":"value","items":_vm.jobLocationTypes,"label":"نوع موقع العمل","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.label)+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(item.label))])]}}]),model:{value:(_vm.jobLocationTypeFilter),callback:function ($$v) {_vm.jobLocationTypeFilter=$$v},expression:"jobLocationTypeFilter"}}),_c('v-select',{attrs:{"rounded":"","dense":"","chips":"","clearable":"","item-value":"id","items":_vm.jobFields,"label":"مجال فرصة العمل","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.fieldFilter),callback:function ($$v) {_vm.fieldFilter=$$v},expression:"fieldFilter"}}),_c('v-slider',{staticClass:"mt-7",attrs:{"thumb-label":"always","thumb-size":42,"min":0,"max":10000000,"ticks":"","step":"200000","label":"الحد الأدنى للراتب"},model:{value:(_vm.minSalaryFilter),callback:function ($$v) {_vm.minSalaryFilter=$$v},expression:"minSalaryFilter"}})]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"outlined":"","text":"","rounded":""},on:{"click":function($event){return _vm.resetFilters()}}},[_vm._v("إزالة عوامل التصفية")]),_c('v-btn',{attrs:{"color":"accent","rounded":""},on:{"click":function($event){return _vm.setFilterState()}}},[_vm._v("تصفية النتائج ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }