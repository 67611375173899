<template>
  <v-row
    justify="center"
    class="mb-5"
    v-if="paginatedCollection.collection.length == 0 && !isLoading"
  >
    <!-- //TODO maybe in future make customized illustration -->
    لم يتم العثور على نتائج......
  </v-row>
</template>

<script>
import { mapState } from "pinia";
import { useGlobalStore } from "@/store/GlobalStore";
import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";

export default {
  props: {
    paginatedCollection: BasePaginatedCollection,
  },
  computed: {
    ...mapState(useGlobalStore, ["isLoading"]),
  },
};
</script>

<style></style>
