const templates = [
  {
    id: "first-template",
  },
  {
    id: "second-template",
  },
];

export default templates;
