export const shiftTypes = [
  {
    label: "دوام كامل",
    value: "full_time"
  },
  {
    label: "دوام جزئي",
    value: "part_time"
  },
  {
    label: "مهام",
    value: "tasks"
  },
  {
    label: "دوام ليلي",
    value: "night_shift"
  }
];
export const experienceLevels = [
  {
    label: "متدرب (خبرة أقل من سنة)",
    value: "0"
  },
  {
    label: "مبتدئ (خبرة سنة أو سنتين)",
    value: "1"
  },
  {
    label: "متوسط (خبرة 3 أو 4 سنوات)",
    value: "2"
  },
  {
    label: "متقدم (خبرة أكثر من 5 سنوات)",
    value: "3"
  }
];
export const jobLocationTypes = [
  {
    label: "عمل عن بعد",
    value: "remotely"
  },
  {
    label: "عمل ضمن موقع عمل ",
    value: "on_site"
  },
  {
    label: "مزيج بين عمل عن بعد وعمل ضمن موقع عمل",
    value: "hybrid"
  },
];
