import PaginatedJobOpportunityCollection from "@/core/classes/models/paginated_collections/PaginatedJobOpportunityCollection";
import { defineStore } from "pinia";
import JobOpportunitiesService from "../services/JobOpportunitiesService";

export const useAppliedJobOpportunitiesStore = defineStore("appliedJobOpportunitiesStore", {
  state: () => ({
    paginatedAppliedJobs: new PaginatedJobOpportunityCollection(),
  }),
  getters: {
    getPageParameters() {
      return new Object({
        page: this.paginatedAppliedJobs.currentPage,
      });
    },
  },
  actions: {
    fetchAppliedJobs() {
      return new Promise((resolve, reject) => {
        JobOpportunitiesService.getAppliedJobs(
          this.getPageParameters
        )
          .then(res => {
            let { data, status } = res;
            return JobOpportunitiesService.resolveStatusResponse(
              status,
              res
            ).then(() => {
                this.paginatedAppliedJobs = new PaginatedJobOpportunityCollection(
                  data
                );
              return resolve(res);
            });
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    fetchNextPage() {
      !this.paginatedAppliedJobs.isLastPage
        ? this.paginatedAppliedJobs.currentPage++
        : console.warn("no more pages!");
      this.fetchAppliedJobs();
    },
    fetchPreviousPage() {
      this.paginatedAppliedJobs.currentPage!=1
        ? this.paginatedAppliedJobs.currentPage--
        : console.warn("no previous pages!");
      this.fetchAppliedJobs();
    }
  }
});
