export default class TrainingProgram{
    id;
    label;
    videoURL;
    condition;

    constructor(data){
        this.id=data?.id;
        this.label=data?.title;
        this.videoURL= process.env.VUE_APP_BASE_URL+data?.introductory_video_path ;
        this.condition=data?.program_conditions ;
    }
}