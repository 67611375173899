export default class Education {
  school;
  field;
  startDate;
  endDate;
  present = false;
  constructor(data) {
    this.school = data?.school;
    this.field = data?.field;
    this.startDate = data?.startDate;
    this.endDate = data?.endDate;
    this.present = data?.present;
  }
}
