<template>
  <BaseAuthenticationWrapper>
    <v-container :class="{ ltr: selectedLanguage == 'en' }">
      <v-btn class="mb-5" @click="changeCurrentLanguage()" color="accent">
        <v-icon small>mdi-swap-horizontal</v-icon>
        {{ $t("change_language") }}
      </v-btn>
      <v-row justify="center">
        <v-col align-self="center" cols="12">
          <v-stepper>
            <!-- Headers -->
            <v-stepper-header>
              <v-stepper-step step="1" editable id="create-step-btn">
                <span class="mx-2"> {{ this.$t("create_resume") }}</span>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="2" editable id="preview-step-btn">
                <span class="mx-2">
                  {{ this.$t("preview_resume") }}
                </span>
              </v-stepper-step>
            </v-stepper-header>
            <!-- Items -->
            <v-stepper-items>
              <v-stepper-content step="1">
                <h3 class="secondary--text text-center my-3 d-md-none"> {{ this.$t("create_resume") }}</h3>
                <CreateResumeStep />
              </v-stepper-content>
              <v-stepper-content step="2">
                <h3 class="secondary--text text-center my-3 d-md-none">   {{ this.$t("preview_resume") }}</h3>
                <PreviewResumeStep />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </BaseAuthenticationWrapper>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useCreateResumeStore } from "../store/CreateResumeStore";
import { EventBus } from "@/main";

import CreateResumeStep from "../components/create_resume_step/CreateResumeStep.vue";
import PreviewResumeStep from "../components/preview_resume_step/PreviewResumeStep.vue";

const createResumeStore = useCreateResumeStore();

export default {
  mounted() {
    this.fetchUserCreatedResume()
      .then()
      .catch((err) => console.error(err));
  },
  components: { CreateResumeStep, PreviewResumeStep },
  computed: {
    ...mapWritableState(useCreateResumeStore, ["selectedLanguage"]),
    ...mapState(useCreateResumeStore, ["resume"]),
  },
  watch: {
    resume: {
      deep: true,
      handler() {
        EventBus.$emit("formChanged");
      },
    },
  },
  methods: {
    ...mapActions(useCreateResumeStore, [
      "fetchUserCreatedResume",
      "changeCurrentLanguage",
    ]),
  },
  destroyed() {
    createResumeStore.$reset();
  },
};
</script>

<style scoped>
.ltr {
  direction: ltr;
}
</style>
