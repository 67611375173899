export default[
    {
        num: 1,
        label: 'اختيار المسار المهني الذي ترغب التسجيل فيه'
    },
    {
        num: 2,
        label: 'مشاهدة الفيديو التعريفي الخاص بالمسار كاملاً'
    },
    {
        num: 3,
        label: 'إدخال البيانات الخاصة بالتسجيل'
    },
    {
        num: 4,
        label: 'إجراء اختبار الميول المهني'
    },
]