<template>
  <div>
    <v-card color="lightSecondary" width="300" min-height="300" rounded="xl" elevation="3">
      <v-container>
        <v-row>
          <v-col align-self="start" cols="4" class="secondary--text">
            <v-avatar color="white" size="75">
              <v-img
                v-if="job.jobPublisher.logoURL"
                contain
                :src="job.jobPublisher.logoURL"
                class="rounded"
              ></v-img>
              <v-icon v-else x-large color="lightSecondary">mdi-domain</v-icon>
            </v-avatar>

            <small class="d-block mt-2">{{ job.jobPublisher.name }}</small>
            <small class="accent--text">{{ job.publishedAt }}</small>
                        <div>
              <v-icon small color="primary">mdi-map-marker</v-icon>
              <small
                v-if="
                  job.workLocationType == 'on_site' ||
                  job.workLocationType == 'hybrid'
                "
              >
                {{ job.workLocation }}
              </small>
              <small v-else>{{ job.trans(job.workLocationType) }}</small>
            </div>
          </v-col>
          <v-col align="start" cols="8">
            <h3 class="mx-5 my-2 secondary--text">{{ job.title }}</h3>
                <v-card rounded="xl">
                  <v-card-text class="primary--text">
                    <div><span class="secondary--text"> المجال:</span> {{ job.pluckFieldNames().toString() }}</div>
                    <div><span class="secondary--text"> الخبرة:</span> {{ job.requiredExperienceLevel }}</div>
                    <div><span class="secondary--text"> نوع الدوام:</span> {{ job.trans(job.shiftType) }}</div>
                    <div v-if="job.minSalary && job.maxSalary">
                      <span class="secondary--text"> الراتب:</span> {{ formatCurrency(job.minSalary) }} - {{ formatCurrency(job.maxSalary) }} 
                    </div>
                  </v-card-text>
              </v-card>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-btn v-if="!job.sentFeedback" @click="openRatingDialog()" color="accent" class="mb-5"
            >أرسل ملاحظة</v-btn
          >
          <v-chip v-else color="accent" class="mb-5" rounded>
                تم إرسال ملاحظتك على الفرصة  <v-icon dark>mdi-check</v-icon></v-chip
              >
        </v-row>
      </v-container>
    </v-card>
    <!--DIALOGS (notes)-->
    <JobRatingDialog
      :showCondition="showRatingDialog"
      :jobId="job.id"
      @closed="closeRatingDialog()"
    />
  </div>
</template>

<script>
import CurrencyFormattingMixin from "@/core/mixins/CurrencyFormattingMixin.vue";
import JobRatingDialog from "./JobRatingDialog.vue";

export default {
  props: {
    job: Object,
  },
  mixins: [CurrencyFormattingMixin],
  components: { JobRatingDialog },
  data() {
    return {
      showRatingDialog: false,
    };
  },
  methods: {
    openRatingDialog() {
      this.showRatingDialog = true;
    },
    closeRatingDialog() {
      this.showRatingDialog = false;
    },
  },
};
</script>

<style scoped></style>
