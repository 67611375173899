<template>
    <v-app>
      <v-container :class="{ ltr: selectedLanguage == 'en' }" fluid>
        <v-row justify="center">
          <!-- Paper -->
          <v-col :style="paperStyle" cols="10">
            <NameAndAbout />
            <ContactInfo />
            <WorkExperiences />
            <TheProjects />
            <ProSkills />
            <TheEducation />
            <TheCertifications />
            <VolunteerExperiences />
            <TheLanguages />
            <SoftSkills />
            <TheReferences />
          </v-col>
          </v-row>
      </v-container>
    </v-app>
  </template>
  
  <script>
  import { mapState } from "pinia";
  import { useCreateResumeStore } from "@/modules/create_resume/store/CreateResumeStore";
  
  import VolunteerExperiences from "./components/VolunteerExperiences.vue";
  import TheCertifications from "./components/TheCertifications.vue";
  import WorkExperiences from "./components/WorkExperiences.vue";
  import TheReferences from "./components/TheReferences.vue";
  import TheEducation from "./components/TheEducation.vue";
  import NameAndAbout from "./components/NameAndAbout.vue";
  import TheLanguages from "./components/TheLanguages.vue";
  import TheProjects from "./components/TheProjects.vue";
  import ContactInfo from "./components/ContactInfo.vue";
  import SoftSkills from "./components/SoftSkills.vue";
  import ProSkills from "./components/ProSkills.vue";
  
  export default {
    components: {
      NameAndAbout,
      ContactInfo,
      SoftSkills,
      ProSkills,
      TheLanguages,
      TheCertifications,
      WorkExperiences,
      VolunteerExperiences,
      TheProjects,
      TheEducation,
      TheReferences,
    },
    computed: {
      ...mapState(useCreateResumeStore, [
        "resume",
        "selectedLanguage",
        "selectedColor",
      ]),
      paperStyle() {
        //TODO font according to locale
        return "background-color: white; color: " + this.selectedColor;
      },
    },
  };
  </script>
  
  <style scoped>
  .ltr {
    direction: ltr;
  }
  </style>
  