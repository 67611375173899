import { BaseError } from "../base/BaseError";
import pinia from "@/store";
import { useAuthStore } from "@/modules/auth/store/AuthStore";

const authStore = useAuthStore(pinia);

export class UnverifiedError extends BaseError {
  message = "يرجى تأكيد البريد الإلكتروني الخاص بك";

  dispatchError() {
    authStore.changeEmailVerificationDialogState(true, true);
  }
}
