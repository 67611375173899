import UserLayout from "@/core/layouts/UserLayout.vue"
import HomePage from "../pages/HomePage.vue";
import FAQsPage from "../pages/FAQsPage.vue";

export default
{
    path: "/",
    component:UserLayout,
    children: [
      {
          path: '',
          name: 'home',
          component: HomePage,
          meta:{title:'الصفحة الرئيسية'}
      },
      {
        path: 'faqs',
        name: 'FAQs',
        component: FAQsPage,
        meta:{title:'الأسئلة الشائعة'}
      },
    ]
}
