<template>
  <v-menu transition="slide-y-transition" bottom offset-y>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom :open-delay="500">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            class="pl-5 pr-5"
            color="primary"
            rounded
            outlined
            text
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }"
          >
            <label v-if="username"> {{ username }}</label>
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <span>الحساب الشخصي</span>
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item link @click="pushProfilePage()">
        <v-icon class="ml-2">mdi-account</v-icon>
        <v-list-item-title>الملف الشخصي</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="changeLogoutDialogVisibility()">
        <v-icon class="ml-2">mdi-logout</v-icon>
        <v-list-item-title>تسجيل الخروج</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { useAuthStore } from "@/modules/auth/store/AuthStore";
import { mapState, mapActions } from "pinia";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(useAuthStore, ["username"])
  },
  methods: {
    ...mapActions(useAuthStore, ["changeLogoutDialogVisibility"]),
    pushProfilePage() {
      this.$router.push({ name: "profile" });
    }
  }
};
</script>
