import BaseService from "@/core/classes/base/BaseService";
import {
  sendRatingAPI,
  getFAQsTagsAPI,
  getFAQsWithFilterAPI,
  getEthicalAdvicesAPI,
} from "./Home.api";
import Mapper from "@/core/classes/mapper/Mapper";
import Client from "@/core/classes/client/Client";

export default class HomeService extends BaseService {
  static sendRating(data) {
    return Client.post(sendRatingAPI, Mapper.camelToUnderscore(data)).then(
      (res) => Promise.resolve(res)
    );
  }
  static getFAQsTags() {
    return Client.get(getFAQsTagsAPI).then((res) => Promise.resolve(res));
  }
  static getPaginatedFAQsWithFilter(filteredTagId = null, pageNumber = 1) {
    return Client.get(getFAQsWithFilterAPI, {
      filter_by: filteredTagId,
      page: pageNumber,
    }).then((res) => Promise.resolve(res));
  }
  static getEthicalAdvices() {
    return Client.get(getEthicalAdvicesAPI).then((res) => Promise.resolve(res));
  }
}
