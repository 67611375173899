<template>
  <v-col cols="12" md="4" align="center" justify="center">
    <AddButton :title="this.$t('links')" @click="openDialog()" />
    <BaseDraggable v-model="resume.links">
      <DeletableCard
        v-for="(link, i) in resume.links"
        :key="i"
        :index="i"
        name="links"
      >
        <p class="primary--text">
          <v-icon>{{ link.icon }}</v-icon> | {{ link.title }}
        </p>
        <p class="primary--text">
          {{ link.url }}
        </p>
      </DeletableCard>
    </BaseDraggable>
    <AddLinkDialog :showCondition="showDialog" @closed="closeDialog()" />
  </v-col>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "../../store/CreateResumeStore";

import AddButton from "../buttons/AddButton.vue";
import DeletableCard from "../DeletableCard.vue";
import AddLinkDialog from "../dialogs/AddLinkDialog.vue";

export default {
  components: { AddButton, DeletableCard, AddLinkDialog },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    ...mapState(useCreateResumeStore, ["resume"]),
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style></style>
