<template>
  <BaseDialog :showCondition="loginDialogState.show" :persistentCondition="loginDialogState.persistent"
    @closed="changeLoginDialogState(false)">
    <template v-slot:title>
      <span class="primary--text"> تسجيل الدخول </span>
    </template>

    <template v-slot:body>
      <v-form ref="form">
        <v-container>
          <v-row justify="center">
            <v-col align="center" cols="9">
              <div v-if="loginDialogState.persistent">
                عليك أولاً أن تقوم بتسجيل الدخول إلى المنصة
              </div>
              <BaseTextField v-model="email" class="mt-3" label="رقم الهاتف المحمول" placeholder="رقم الهاتف المحمول"
                name="رقم الهاتف المحمول" :rules="[requiredRule, phoneNumberRule]" @keyup.enter="validateThenPostLogin()">
              </BaseTextField>

              <BasePasswordField label="كلمة السر" placeholder="كلمة السر" name="كلمة السر" type="password"
                v-model="password" :rules="[requiredRule, passwordRule]" @keyup.enter="validateThenPostLogin()">
              </BasePasswordField>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>

    <template v-slot:actions>
      <v-row justify="center">
        <v-col align="center">
          <v-row justify="space-around">
            <v-btn @click="validateThenPostLogin()" class="accent rounded-pill px-10 mt-5">
              تسجيل الدخول
            </v-btn>
            <v-btn text outlined rounded v-if="loginDialogState.persistent && !isWebView"
              @click="cancelAndRedirectToHome()" class="primary--text mt-5">
              العودة للصفحة الرئيسية
            </v-btn>
          </v-row>

          <div class="mt-5">
            <v-btn text @click="showRegisterDialog()">
              ليس لديك حساب؟
              <span class="accent--text text-decoration-underline">
                أنشئ حساباً
              </span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/core/base/dialogs/BaseDialog.vue";
import { mapState, mapActions } from "pinia";
import { useAuthStore } from "../store/AuthStore";
export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  props: {},
  components: { BaseDialog },
  computed: {
    ...mapState(useAuthStore, ["loginDialogState"]),
    isWebView() {
      return this.$route.query.webView;
    },
  },
  methods: {
    ...mapActions(useAuthStore, [
      "login",
      "changeLoginDialogState",
      "changeRegisterDialogState",
    ]),
    cancelAndRedirectToHome() {
      this.changeLoginDialogState(false);
      this.$router.push("/");
    },
    validateThenPostLogin() {
      if (this.$refs.form.validate()) {
        var loginData = {
          login_key: this.email,
          password: this.password,
        };
        this.login(loginData).then(() => this.$router.go());
      }
    },
    showRegisterDialog() {
      this.changeLoginDialogState(false);
      this.changeRegisterDialogState(true, this.loginDialogState.persistent);
    },
  },
};
</script>

<style scoped></style>
