import UserLayout from "@/core/layouts/UserLayout.vue";
import NewsPage from "../pages/NewsPage.vue";
import NewsDetailsPage from "../pages/NewsDetailsPage.vue";

export default {
  path: "/news",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "news",
      component: NewsPage,
      meta: {
        title: "الأخبار",
      },
    },
    {
      path: "/news/:id",
      name: "news_details",
      component: NewsDetailsPage,
      meta: {
        title: "تفاصيل خبر", //TODO: add news title to page title?
      },
    },
  ],
};
