<template>
  <BaseDialog
    :showCondition="isLogoutDialogVisible"
    @closed="changeLogoutDialogVisibility()"
  >
    <template v-slot:title>
      <span class="primary--text"> تسجيل الخروج </span>
    </template>

    <template v-slot:body>
      <v-form ref="form">
        <v-container>
          <v-row justify="center">
            <v-col align="center" cols="9">
              <p>هل أنت متأكد من أنك تريد تسجيل الخروج؟</p>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>

    <template v-slot:actions>
      <v-row justify="space-around">
        <v-btn
          @click="changeLogoutDialogVisibility()"
          class="lightNeutral rounded-pill px-10">
          لا
        </v-btn>
        <v-btn @click="logoutAndRedirectHome()" class="accent rounded-pill px-10">
           نعم 
        </v-btn>
      </v-row>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/core/base/dialogs/BaseDialog.vue";
import { mapState, mapActions } from "pinia";
import { useAuthStore } from "../store/AuthStore";
export default {
  components: { BaseDialog },
  computed: {
    ...mapState(useAuthStore, ["isLogoutDialogVisible"]),
  },
  methods: {
    ...mapActions(useAuthStore, ["changeLogoutDialogVisibility", "logout"]),
    logoutAndRedirectHome(){
      this.logout().then(()=>{
        this.$router.push("/");
      });
    }
  },
};
</script>

<style scoped></style>
