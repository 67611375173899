export default [
  {
    title: "معايير التسجيل على المنحة التدريبية",
    htmlContent: ` 1- ألا يقل عمر المتدرب عن 18 عام ولا يزيد عن 35 عام.<br>

        2- إنهاء المرحلة الثانوية للطلاب "قيد الدراسة " ويؤجل تسجيل أي طالب في المرحلة الإعدادية أو
        الثانوية حتى ينهي دراسته .<br>

        3- توفر المتطلبات الأساسية الخاصة بكل برنامج تدريبي كالمستوى العلمي ونتيجة مقابلة تحديد
        المسار المهني.<br>

        4- تجاوز مقابلة تحديد المسار المهني التي يتم من خلالها دراسة المهنة المناسبة للمتقدم وفرص
        نجاحه في المهنة المراد التدرب عليها.<br>`
  },
  {
    title:`شروط التسجيل ضمن البرنامج التدريبي المختار`,
  },
  {
    title:`آلية الدعوة لحضور البرامج التدريبية`,
    htmlContent:`
    1- بعد إتمام وتجاوز مراحل التسجيل والمقابلات تقوم الجمعية بالاتصال أو إرسال رسالة نصية
    بالمتدرب المقبول تتضمن الدعوة للتدريب في أحد البرامج التدريبية المعتمدة في الجمعية ويعد ذلك
    بمثابة تبليغ رسمي لحضور التدريب ويتحمل المتدرب مسؤولية عدم جاهزية رقم جواله المسجل في الطلب
    لاستقبال الرسائل النصية المرسلة من إدارة التدريب في الجمعية.<br>

    2- يحق للمتدرب الاعتذار مرتين فقط عن حضور برنامج التدريب الذي تتم دعوته إليه لظروف خاصة أو
    لعدم مناسبة أوقات التدريب ويعتبر طلبه بعد ذلك ليس أولوية (وتعتبر عدم المراجعة أو الاتصال بعد
    إرسال الرسالة النصية المتضمنة دعوة لحضور برنامج التدريب بمثابة اعتذار) ويطوى طلب التدريب بعد
    الاعتذار الثالث مهما كانت الأسباب.<br>

    3- يبدأ كل برنامج تدريبي بدورة مبادئ أساسية في المهنة ويتم بعدها ترشيح المتدربين الذين
    يجتازونها بنجاح لبقية الدورات التي يتضمنها البرنامج التدريبي الذي رُشِّحوا له بما في ذلك
    (دورة أخلاقيات المهنة).<br>

    4- يفصل كل متدرب تجاوزت أيام الغياب 20% من ساعات البرنامج التدريبي كاملاً تشمل التأخير
    والغياب (المبرر وغير المبرر).<br>


    5- تعتبر دورة أخلاقيات العمل جزءاً اساسياً من البرنامج التدريبي ويتوجب على المتدرب حضورها
    كاملة واجتيازها بنجاح , حتى يحصل على شهادة التخرج من البرنامج التدريبي.<br>

    6- تعطى الأولوية دائماً للأشخاص المقبولين في الدورات المجانية بعد دراسة الطلب من قبل القسم
    الاجتماعي .<br>

    7- تقتضي مصلحة التدريب توثيق وتصوير الأنشطة المقامة في الجمعية ولا مانع عندي من وجودي ضمن
    صور الدورات .<br>

    - يحق للمتدرب الاستفادة من برنامج تدريبي واحد فقط.<br>
    - لا تلتزم الجمعية بتقديم أدوات تدريبية في نهاية التدريب.<br>
    في حال الموافقة على الشروط والآليات المبينة أعلاه يمكنك تعبئة (طلب التسجيل على المنحة
    التدريبية).<br>
    - يحق للجمعية الاعتذار عن قبول الطلب دون إبداء الأسباب.<br>
    - تعتبر أي معلومة ناقصة أو غير صحيحة سبباً كافياً لإلغاء الطلب.<br>`
  },
  {
    title:"يوجد تسجيل مسبق؟",
    htmlContent:` - اذا كنت قد سجلت طلب منحة تدريبية مسبقاً، يرجى عدم استكمال الطلب الحالي والتواصل معنا
    هاتفياً لمعرفة حالة الطلب على أحد الأرقام الاتية:
    2232378-0991386666<br>
    - إعادة التسجيل مرات متكررة تؤخِّر التواصل معكم.<br>`
  }
];
