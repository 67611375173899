import UserLayout from "@/core/layouts/UserLayout.vue";
import ApplicationIntroPage from "../pages/ApplicationIntroPage.vue";
import ApplicationFormPage from "../pages/ApplicationFormPage.vue";
import BaseFormPageWrapper from "@/core/base/wrappers/BaseFormPageWrapper.vue";

export default {
  path: "/training-programs",
  component: UserLayout,
  children: [
    {
      path: "/training-programs",
      name: "training_program_intro",
      component: ApplicationIntroPage,
      meta: { title: "المنح التدريبية" },
    },
    {
      path: "/training-programs/:id/apply",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "training_program_form",
          component: ApplicationFormPage,
          meta: { title: "التقدم لمنحة تدريبية", requiresAuth: true },
        },
      ],
    },
  ],
};
