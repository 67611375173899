<template>
  <v-container v-if="resume.references.length > 0" class="pt-0 pb-0 mb-3">
    <BaseDivider :title="this.$t('references')" />
    <div class="mb-2" v-for="(reference, i) in resume.references" :key="i">
      <template>
        <h4 class="ma-0 pa-0">{{ reference.name }} | {{ reference.jobTitle }}</h4>
        <p class="ma-0 pa-0">
          {{ reference.description }} <br />
          {{ reference.contactInfo }}
        </p>
      </template>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "@/modules/create_resume/store/CreateResumeStore";
import BaseDivider from "./BaseDivider.vue";
export default {
    computed: {
        ...mapState(useCreateResumeStore, ["resume"]),
    },
    components: { BaseDivider }
};
</script>

<style scoped></style>
