<template>
  <v-container class="pa-0 ma-0 mb-2">
    <h1 v-if="this.resume.name" class="name pa-0 ma-0">
      {{ this.resume.name }}
    </h1>
    <p v-if="this.resume.about" class="about pa-0 ma-0">
      {{ this.resume.about }}
    </p>
  </v-container>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "@/modules/create_resume/store/CreateResumeStore";
export default {
  computed: {
    ...mapState(useCreateResumeStore, ["resume"]),
  },
};
</script>

<style>
.name {
  font-size: 45px;
  font-weight: bold;
}
.about {
  opacity: 0.8;
}
</style>
