<template>
  <v-container>
    <v-col align="center" justify="center">
      <ResumeTemplate />
      <h1 class="text-center primary--text my-4">{{ $t("pick_a_theme") }}</h1>
      <v-item-group mandatory @change="changeSelectedTemplate">
        <v-container>
          <v-row align="center" justify="center">
            <v-col
              v-for="template in templates"
              :key="template.id"
              cols="12"
              md="4"
              align="center"
            >
              <v-item v-slot="{ active, toggle }">
                <v-card
                  :class="{ 'bordered-card': active }"
                  dark
                  height="300"
                  width="200"
                  @click="toggle"
                >
                  <v-img
                    :src="require('@/assets/templates/' + template.id + '.png')"
                    height="100%"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          color="accent"
                          indeterminate
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>
      <h1 class="text-center primary--text my-4">{{ $t("pick_a_color") }}</h1>
      <v-color-picker
        v-model="selectedColor"
        hide-canvas
        hide-mode-switch
        hide-inputs
      ></v-color-picker>
      <v-btn class="accent rounded-xl my-2" x-large @click="validateNPreview()">
        <v-icon dark class="mx-2">mdi-eye</v-icon>
        {{ $t("preview") }}
      </v-btn>
      <v-btn
        id="save-and-download-btn"
        class="accent rounded-xl my-2 mx-5"
        x-large
        @click="openAlertDialog()"
      >
        <v-icon dark class="mx-2">mdi-content-save</v-icon>
        {{ $t("save_and_download") }}
      </v-btn>
    </v-col>
    <ProfilePictureAlertDialog
      :showCondition="showAlertDialog"
      @closed="closeAlertDialog()"
    />
    <PreviewResumeDialog
      :showCondition="showPreviewDialog"
      @closed="closeShowPreviewDialog()"
    />
  </v-container>
</template>

<script>
import ResumeTemplate from "./resume_templates/ResumeTemplate.vue";
import TemplatesList from "./statics/TemplatesList";
import Html2PdfMixin from "./statics/Html2PdfMixin.vue";
import ProfilePictureAlertDialog from "../dialogs/ProfilePictureAlertDialog.vue";

import { Notify } from "@/core/classes/notify/Notify";
import { mapActions, mapState, mapWritableState } from "pinia";
import { useCreateResumeStore } from "../../store/CreateResumeStore";
import PreviewResumeDialog from "../dialogs/PreviewResumeDialog.vue";

export default {
  components: {
    ResumeTemplate,
    ProfilePictureAlertDialog,
    PreviewResumeDialog,
  },
  mixins: [Html2PdfMixin],
  data() {
    return {
      templates: TemplatesList,
      showAlertDialog: false,
    };
  },
  computed: {
    ...mapWritableState(useCreateResumeStore, [
      "selectedTemplate",
      "selectedColor",
      "showPreviewDialog",
    ]),
    ...mapState(useCreateResumeStore, ["resumeHasPersonalInfo"]),
  },
  methods: {
    ...mapActions(useCreateResumeStore, ["sendUserCreatedResume"]),
    changeSelectedColor(color) {
      console.log(color);
    },
    changeSelectedTemplate(index) {
      this.selectedTemplate = index;
    },
    openAlertDialog() {
      this.showAlertDialog = true;
    },
    closeAlertDialog() {
      this.showAlertDialog = false;
    },
    closeShowPreviewDialog() {
      this.showPreviewDialog = false;
    },
    validateNPreview() {
      if (this.resumeHasPersonalInfo) {
        const templateId = this.templates[this.selectedTemplate].id;
        this.previewPDF(templateId);
      } else {
        Notify.showMessage("error", this.$t("required_personal_info"));
      }
    },
  },
};
</script>

<style scoped>
.bordered-card {
  border: 3px solid var(--v-primary-base);
  border-radius: 15px;
}
</style>
