export default class Certificate {
  issuer;
  name;
  description;
  issueDate;
  link;
  constructor(date) {
    this.issuer = date?.issuer;
    this.name = date?.name;
    this.description = date?.description;
    this.issueDate = date?.issueDate;
    this.link = date?.link;
  }
}
