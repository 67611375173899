<template>
  <v-footer class="primary lighten-1 footer">
    <v-container fluid class="fill-height">
      <v-row>
        <FooterLogo></FooterLogo>
        <FooterItem>
          <template v-slot:content>
            <p>
              اتصل بنا <br />
              الجوال: 099108666
              <br />
              الهاتف: 0115118241
            </p>

            <p>
              البريد الالكتروني<br />
              <a href="mailto:info@albarakah.net" class="email">
                info@albarakah.net
              </a>
            </p>
          </template>
        </FooterItem>
        <FooterItem>
          <template v-slot:content>
            <p>
              أرقام حساباتنا البنكية <br />
              بنـك البــركـة الإســلامـي<br />
              حساب الزكاة : 0/7000676 <br />حساب الصدقات : 1/7000676 <br />
              <br>
              بنك سورية الدولي الإسلامي <br />
              حساب الزكاة : 00/ 201434<br />حساب الصدقات: 01/ 201434
            </p>
          </template>
        </FooterItem>
        <FooterItem>
          <template v-slot:content>
            <p>
              العنوان <br />سوريا، دمشق، مقابل مشفى <br />ابن النفيس، مقسم 42
            </p>
            <a
              class="mx-3"
              href="https://twitter.com/albarakahnet"
              target="_blank"
            >
              <v-icon color="secondary" size="35px">mdi-twitter</v-icon>
            </a>
            <a
              class="mx-3"
              href="https://www.facebook.com/albarakah.net"
              target="_blank"
            >
              <v-icon color="secondary" size="35px">mdi-facebook</v-icon>
            </a>
            <!-- TODO: add real application link  -->
            <a class="mx-3" :href="appLink" target="_blank">
              <!-- <v-icon color="secondary" size="35px">mdi-cellphone</v-icon>  -->
              <v-icon color="secondary" size="35px">mdi-android</v-icon>
            </a>
          </template>
        </FooterItem>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import FooterLogo from "./components/FooterLogo.vue";
import FooterItem from "./components/FooterItem.vue";
export default {
  components: { FooterLogo, FooterItem },

  computed: {
    appLink() {
      return process.env.VUE_APP_ALBARAKAH_MOBILE;
    },
  },
};
</script>

<style scoped>
.footer {
  margin-top: 50px;
}
.container {
  margin: 25px auto;
}
.email {
  color: inherit;
  text-decoration: none;
}
</style>
