<template>
  <div class="display-none">
    <FirstTemplate id="first-template" />
    <SecondTemplate id="second-template" />
  </div>
</template>

<script>
import FirstTemplate from "./FirstTemplate/FirstTemplate.vue";
import SecondTemplate from "./SecondTemplate/SecondTemplate.vue";

export default {
  components: { FirstTemplate, SecondTemplate },
};
</script>

<style>
.display-none {
  display: none;
}
</style>
