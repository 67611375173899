<template>
  <div>
    <span class="primary--text">{{ this.title }}</span>
    <v-rating
      readonly
      :value="proficiency"
      full-icon="mdi-circle"
      empty-icon="mdi-circle-outline"
      length="5"
      size="15px"
    ></v-rating>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    proficiency: Number,
  },
};
</script>

<style></style>
