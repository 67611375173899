import Tag from './Tag';
export default class FAQ{
    id;
    question;
    answer;
    tag;
    tagObject;
    constructor(data){
        this.id=data.id;
        this.question=data.question;
        this.answer=data.answer;
        this.tagObject=new Tag(data?.category);
        this.tag=this.tagObject?.name;
    }
}