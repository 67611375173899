import Media from "@/core/classes/models/Media";

export default class Project {
  id;
  title;
  description;
  accomplisherName;
  trainingProgramName;
  accomplishmentDate;
  githubLink;
  behanceLink;
  medias = new Array();
  coverPath;
  constructor(data) {
    this.id = data?.id;
    this.title = data?.title;
    this.description = data?.description;
    this.accomplisherName = data?.accomplisher_name;
    this.trainingProgramName= data?.training_program?.title;
    this.githubLink = data?.github_link;
    this.behanceLink = data?.behance_link;
    this.accomplishmentDate = data?.accomplishment_date;
    data?.medias.map((item) => {
      let media = new Media(item);
      this.medias.push(media);
      if (media.isCover == 1) {
        this.coverPath = media.localPath;
      }
    });
  }
}
