import Field from "@/core/classes/models/Field";

export default class Profile {
  id;
  name;
  username;
  email;
  phoneNumber;
  studyField;
  interestedFields=[];
  emailVerifiedAt;
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.username = data.username;
    this.email = data.email;
    this.phoneNumber = data.phone_number;
    this.studyField = data.study_field;
    this.emailVerifiedAt = data.email_verified_at;
    data?.fields?.map((field)=>{
      this.interestedFields.push(new Field(field))
    })
  }
  pluckFieldNames(){
    var names=[];
    this.interestedFields.map((field)=>{
      names.push(field.name);
    })
    return names;
  }
}
