import { render, staticRenderFns } from "./FAQItem.vue?vue&type=template&id=747b7570&scoped=true&"
import script from "./FAQItem.vue?vue&type=script&lang=js&"
export * from "./FAQItem.vue?vue&type=script&lang=js&"
import style0 from "./FAQItem.vue?vue&type=style&index=0&id=747b7570&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "747b7570",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VCard,VCardText,VCardTitle,VChip})
