<template>
  <v-col cols="12" md="4" align="center" justify="center">
    <AddButton :title="this.$t('languages')" @click="openDialog()" />
    <BaseDraggable v-model="resume.languages">
      <DeletableCard
        v-for="(lang, i) in resume.languages"
        :key="i"
        :index="i"
        name="languages"
      >
        <ProficiencyRate :title="lang.name" :proficiency="lang.proficiency" />
      </DeletableCard>
    </BaseDraggable>
    <AddLanguageDialog :showCondition="showDialog" @closed="closeDialog()" />
  </v-col>
</template>

<script>
import { mapWritableState } from "pinia";
import { useCreateResumeStore } from "../../store/CreateResumeStore";

import AddButton from "../buttons/AddButton.vue";
import AddLanguageDialog from "../dialogs/AddLanguageDialog.vue";
import ProficiencyRate from "../ProficiencyRate.vue";
import DeletableCard from "../DeletableCard.vue";
import BaseDraggable from "@/core/base/draggable/BaseDraggable.vue";

export default {
  components: {
    AddButton,
    AddLanguageDialog,
    ProficiencyRate,
    DeletableCard,
    BaseDraggable,
  },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    ...mapWritableState(useCreateResumeStore, ["resume"]),
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style></style>
