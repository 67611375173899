<script>
import html2pdf from "html2pdf.js";

import pinia from "@/store";
import { useGlobalStore } from "@/store/GlobalStore";
import { useCreateResumeStore } from "@/modules/create_resume/store/CreateResumeStore";
import { mapState } from "pinia";

const globalStore = useGlobalStore(pinia);
const createResumeStore = useCreateResumeStore(pinia);

export default {
  computed: {
    ...mapState(useCreateResumeStore, ["resume"]),
  },
  data() {
    return {
      opt: {
        image: { type: "png", quality: 0.4 },
        html2canvas: { scale: 2, letterRendering: true },
        jsPDF: { unit: "pt", orientation: "portrait", compress: true },
        pagebreak: { mode: ["avoid-all"] },
      },
    };
  },
  methods: {
    getTemplateNodeAndResolveHeight(id) {
      var templateElement = document.getElementById(id);
      var clonedElement = templateElement.cloneNode(true);
      //fix edge height in case of first template
      if (id == "first-template") {
        // Append the cloned element to the document body
        document.body.appendChild(clonedElement);

        //resolving the height of edge element in first template
        var edgeElement = clonedElement.querySelector("#edge");
        var contentHeight = clonedElement.scrollHeight;
        var contentHeightPt = contentHeight * 0.75;
        // calculate the number of A4 pages needed to fit the content
        var numPages = Math.ceil(contentHeightPt / 842);
        // set the height of the element to a multiple of A4 in "pt" units
        edgeElement.style.height = numPages * 842 - 1 + "pt";
      }
      clonedElement.remove();
      return clonedElement;
    },
    previewPDF(id) {
      globalStore.changeLoadingStatus(true);
      const templateElement = this.getTemplateNodeAndResolveHeight(id);
      html2pdf()
        .set(this.opt)
        .from(templateElement)
        .outputPdf()
        .get("pdf")
        .then(function (pdfObj) {
          globalStore.changeLoadingStatus(false);
          createResumeStore.showPreviewDialog = true;
          createResumeStore.tempResumePDFFileURL = pdfObj.output("bloburl");
        });
    },
    downloadPdf(id, onDownloaded) {
      globalStore.changeLoadingStatus(true);
      const templateElement = this.getTemplateNodeAndResolveHeight(id);
      this.opt.filename = this.resume.name ?? "resume" + ".pdf";
      html2pdf()
        .set(this.opt)
        .from(templateElement)
        .save()
        .then(() => {
          globalStore.changeLoadingStatus(false);
          onDownloaded();
        });
    },
  },
};
/*
  FIXING HEIGHT DEPENDING ON PDF FILE PAGES NUMBER
   _getNumberOfPages(el) {
    return new Promise((resolve, reject) => {
      html2pdf()
        .set(this.opt)
        .from(el)
        .outputPdf()
        .get("pdf")
        .then((pdf) => {
          var numPages = pdf.getNumberOfPages();
          return resolve(numPages);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  _preview(el) {
    html2pdf()
      .set(this.opt)
      .from(el)
      .outputPdf()
      .get("pdf")
      .then(function (pdfObj) {
        globalStore.changeLoadingStatus(false);
        createResumeStore.showPreviewDialog = true;
        createResumeStore.tempResumePDFFileURL = pdfObj.output("bloburl");
      });
  },
  _download(el, onDownloaded) {
    html2pdf()
      .set(this.opt)
      .from(el)
      .save()
      .then(() => {
        globalStore.changeLoadingStatus(false);
        onDownloaded();
      });
  },
  previewPDF(id) {
    globalStore.changeLoadingStatus(true);
    const element = document.getElementById(id);
    var clonedElement = element.cloneNode(true);
    // If the first templated is selected, we need to get the pdf pages for fixing edge height
    if (id == "first-template") {
      var edge = clonedElement.querySelector("#edge");
      this._getNumberOfPages(clonedElement).then((numPages) => {
        edge.style.height = numPages * 1121 + "px"; // 1121 is A4 stander size in px
        this._preview(clonedElement);
      });
    } else {
      this._preview(clonedElement);
    }
  },
  downloadPdf(id, onDownloaded) {
    globalStore.changeLoadingStatus(true);
    const element = document.getElementById(id);
    var clonedElement = element.cloneNode(true);
    this.opt.filename = this.resume.name ?? "resume" + ".pdf";
    // If the first templated is selected, we need to get the pdf pages for fixing edge height
    if (id == "first-template") {
      var edge = clonedElement.querySelector("#edge");
      this._getNumberOfPages(clonedElement).then((numPages) => {
        edge.style.height = numPages * 1121 + "px"; // 1121 is A4 stander size in px
        this._download(clonedElement, onDownloaded);
      });
    } else {
      this._download(clonedElement, onDownloaded);
    }
  },
*/
</script>
