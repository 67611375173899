<template>
  <BaseDialog
    :ltr="selectedLanguage == 'en'"
    :showCondition="show"
    @closed="closeDialog()"
  >
    <template v-slot:title>{{ $t("alert") }}</template>
    <template v-slot:body>
      <p class="text-center">{{ $t("profile_picture_not_saved") }}</p>
    </template>
    <template v-slot:actions>
      <v-btn id="save-confirmation-btn" color="accent" rounded @click="validateNSaveNDownload()">
        {{ $t("ok") }}
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import { Notify } from "@/core/classes/notify/Notify";
import { useCreateResumeStore } from "../../store/CreateResumeStore";
import { mapActions, mapState } from "pinia";

import TemplatesList from "../preview_resume_step/statics/TemplatesList";
import Html2PdfMixin from "../preview_resume_step/statics/Html2PdfMixin.vue";

export default {
  mixins: [Html2PdfMixin],
  data() {
    return {
      templates: TemplatesList,
      show: this.showCondition,
    };
  },
  props: {
    showCondition: Boolean,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  computed: {
    ...mapState(useCreateResumeStore, [
      "selectedTemplate",
      "selectedLanguage",
      "resumeHasPersonalInfo",
    ]),
  },
  methods: {
    ...mapActions(useCreateResumeStore, ["sendUserCreatedResume"]),
    validateNSaveNDownload() {
      if (this.resumeHasPersonalInfo) {
        this.sendUserCreatedResume()
          .then(() => {
            const templateId = this.templates[this.selectedTemplate].id;
            this.downloadPdf(templateId, () => {
              this.closeDialog();
            });
          })
          .catch((err) => console.error(err));
      } else {
        Notify.showMessage("error", this.$t("required_personal_info"));
      }
    },
    closeDialog() {
      this.show = false;
      this.$emit("closed");
    },
  },
};
</script>
